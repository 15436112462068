<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('device.name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valCode"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
        <div class="item">
          <span class="label">{{ $t('device.operate_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.online_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valAlert" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.alerts" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName" :placeholder="$t('device.no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.site_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valSiteName"
            :placeholder="$t('device.site_name')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%" @row-click="func.showDetails">
        <el-table-column prop="Id" label="Id" width="80">
        </el-table-column>
        <el-table-column :label="$t('device.name')" width="180" prop="Name"></el-table-column>
        <el-table-column prop="SiteName" :label="$t('device.site_name')" width="140">
        </el-table-column>
        <el-table-column prop="Address" :label="$t('device.address')" width="140">
        </el-table-column>
        <el-table-column prop="DevId" :label="$t('device.no')" width="260">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column width="100" :label="$t('device.alert')">
          <template #default="scope">
            <el-tag :class="scope.row.Alert == 0 ? 'tag-success' : 'tag-fail'" effect="dark">
              {{ scope.row.Alert == 0 ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="$t('device.online_status')">
          <template #default="scope">
            <el-tag :class="scope.row.IsOnline == 0 ? 'tag-fail' : 'tag-success'" effect="dark">
              {{ scope.row.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.status')">
          <template #default="scope">
            <el-tag :class="scope.row.Status == 1 ? 'tag-success' : 'tag-fail'" effect="dark">
              {{ scope.row.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column width="100" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <!-- <el-tag @click="func.rowDblClick(scope.row)">{{ $t('g.edit') }}</el-tag> -->
            <el-button @click="func.rowDblClick(scope.row)" class="btn-edit" type="primary"
              style="margin-left: 10px;">{{ $t('g.edit')
              }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize }}
        </div>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogVisible" width="60%" align-center>
      <el-form label-width="160px" :model="formData.curData">
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.status')">
            <el-select class="inp" v-model="formData.curData.Status" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('member.is_release')">
            <el-select class="inp" v-model="formData.curData.isrelease" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.release" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.site_name')">
            <el-select class="inp" v-model="formData.curData.PileSiteId" :placeholder="$t('g.choose')">
              <el-option v-for="(item, index) in formData.pilesiteList" :key="index" :label="item.Name"
                :value="item.Id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('rights.agent')" v-if="formData.is_opt">
            <el-cascader style="width: 100%;" :clearable="true" v-model="formData.curData.agentCode"
              :props="formData.props2" :options="render.cascade_options_direct" disabled />
          </el-form-item>
          <el-form-item :label="$t('g.operator')">
            <el-cascader style="width: 100%;" :clearable="true" v-model="formData.curData.agentPath"
              :props="formData.props" :options="render.cascade_options_direct" />
          </el-form-item>
          <el-form-item :label="$t('coupon.type')">
            <el-select class="inp" v-model="formData.curData.Type" :placeholder="$t('g.choose')" disabled>
              <el-option v-for="item in render.pileType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.carPile.maxPower')">
            <el-input :value="formData.curData.MaxPower" disabled>
              <template #append>KW</template>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('device.carPile.totalGunNum')">
            <el-input :value="formData.curData.PileNum" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.no')">
            <el-input :value="formData.curData.DevId" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.name')">
            <el-input :value="formData.curData.Name" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.specs')">
            <el-input :value="formData.curData.Specs" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.pattern')">
            <el-input :value="formData.curData.ModeNo" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('g.create_at')">
            <el-input :value="formData.curData.CreateAtStr" disabled></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" style="width:80%;" align-center>
      <el-descriptions border column=3>
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>


        <el-descriptions-item :label="$t('device.online_status')">
          <el-tag :class="formData.curData.IsOnline == 0 ? 'tag-info' : 'tag-success'">
            {{ formData.curData.IsOnline == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('member.is_release')">
          <el-tag :class="formData.curData.IsRelease == false ? 'tag-info' : 'tag-success'">
            {{ formData.curData.IsRelease == false ? $t('g.no') : $t('g.yes') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('coupon.type')">{{ formData.curData.Type == 5 ?
          $t('device.carPile.directCurrent')
          : $t('device.carPile.altCurrent')
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.operator')">{{ formData.curData.CodePath }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.carPile.billingMethod')">
          <span v-if="formData.curData.ChargeType == 1">{{ $t('device.carPile.method.time') }}</span>
          <span v-if="formData.curData.ChargeType == 2">{{ $t('device.carPile.method.grade') }}</span>
          <span v-if="formData.curData.ChargeType == 3">{{ $t('device.carPile.method.power') }}</span>
        </el-descriptions-item>
        <!-- <el-descriptions-item label="站点电话" :label="$t('device.address')">{{ formData.curData.SiteMobile
          }}</el-descriptions-item> -->
        <el-descriptions-item :label="$t('device.site_name')">{{ formData.curData.SiteName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.address')">{{ formData.curData.Address }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.carPile.siteId')">{{ formData.curData.PileSiteId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DevId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.name')">{{ formData.curData.Name
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.carPile.totalGunNum')">{{ formData.curData.PileNum
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.alert')">
          <el-tag :class="formData.curData.Alert == 0 ? 'tag-success' : 'tag-fail'">
            {{ formData.curData.Alert == 0 ? $t('g.no') : $t('g.yes') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.status')">
          <el-tag :class="formData.curData.Status == 0 ? 'tag-fail' : 'tag-success'">
            {{ formData.curData.Status == 1 ? $t('device.statuses.enabled') : $t('device.statuses.disabled') }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.mac')">{{ formData.curData.MacAddr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.carPile.operatePara')">{{ formData.curData.OperConf
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.specs')">{{ formData.curData.Specs }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.pattern')">{{ formData.curData.ModeNo }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.temp')">{{ formData.curData.Temp }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.carPile.maxPower')">{{ formData.curData.MaxPower
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.carPile.realTimePower2')">{{ formData.curData.MeasPower
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.carPile.realtimePower')">{{ formData.curData.MeasEnergy
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.life_time')">{{
          func.toCounterTime(formData.curData.LifeTime)
        }}</el-descriptions-item>
      </el-descriptions>
      <el-table :data="formData.gun_list" border style="margin-top:20px;text-align:center;width:100%;">
        <el-table-column prop="DevId" :label="$t('device.carPile.gunNo')" width="100"></el-table-column>
        <el-table-column prop="GunStatus" :label="$t('device.carPile.gunStatus')">
          <template #default="scope">
            <el-tag :class="(scope.row.GunStatus == 2) ? 'tag-fail' : 'tag-success'">
              <span v-if="scope.row.GunStatus == 0">{{ $t('device.carPile.status.idle') }}</span>
              <span v-else-if="scope.row.GunStatus == 1">{{ $t('device.carPile.status.notCharging') }}</span>
              <span v-else-if="scope.row.GunStatus == 2">{{ $t('device.carPile.status.charging') }}</span>
              <span v-else-if="scope.row.GunStatus == 3">{{ $t('device.carPile.status.noUploadGun') }}</span>
              <span v-else-if="scope.row.GunStatus == 4">{{ $t('device.carPile.status.appointProgress') }}</span>
              <span v-else-if="scope.row.GunStatus == 5">{{ $t('device.carPile.status.fault') }}</span>
            </el-tag>


          </template>
        </el-table-column>
        <el-table-column prop="Current" :label="$t('device.meas_power')">
          <template #default="scope">
            {{ (Number(scope.row.Current * scope.row.Voltage) / 1000000000).toFixed(2) + 'KW' }}
          </template>
        </el-table-column>
        <el-table-column prop="Current" :label="$t('device.current')">
          <template #default="scope">
            {{ (Number(scope.row.Current) / 1000).toFixed(2) + 'A' }}
          </template>
        </el-table-column>
        <el-table-column prop="Voltage" :label="$t('device.volt')">
          <template #default="scope">
            {{ (Number(scope.row.Voltage) / 1000).toFixed(2) + 'V' }}
          </template>
        </el-table-column>
        <el-table-column prop="UpdateAtStr" :label="$t('g.update_at')" width="180"></el-table-column>
        <el-table-column :label="$t('g.operation')" :width="isEnVersion() ? 220 : 170">
          <template #default="scope2">
            <el-button @click="func.qrcode(scope2.row, formData.curData)" class="btn-qrcode" type="primary">{{
              $t('g.QRCode')
            }}</el-button>
            <el-button @click="func.tapStatus(scope2.row.DevId, formData.curData.DevId)" type="primary">{{
              $t('order.unlock') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-table :data="formData.cost_allocation" border style="margin-top:20px;text-align:center;width:100%;">
        <el-table-column prop="typeName" label="时段类型"></el-table-column>
        <el-table-column prop="strtime" label="开始时间"></el-table-column>
        <el-table-column prop="endtime" label="结束时间"></el-table-column>
        <el-table-column prop="electricity_bill" label="电费(元/度)">
          <template #default="scope">
            {{ Number(scope.row.electricity_bill) / 1000 }}
          </template>
        </el-table-column>
        <el-table-column prop="service_charge" label="服务费(元/度)">
          <template #default="scope">
            {{ Number(scope.row.service_charge) / 1000 }}
          </template>
        </el-table-column>
        <el-table-column label="总计">
          <template #default="scope">
            {{ ((Number(scope.row.electricity_bill) + Number(scope.row.service_charge)) / 1000).toFixed(2) + '(元/度)' }}
          </template>
        </el-table-column>
      </el-table> -->
    </el-dialog>
    <el-dialog class="qrcode" v-model="formData.dialogQrcodeVisible" align-center>
      <div>{{ formData.qrcodeMsg }} </div>
      <canvas id="qrcode" style="width:300px;height:300px;border:1px solid black;margin-top: 20px;"></canvas>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, nextTick } from 'vue'
import { shortcuts, operators, cascade_options_direct } from '@/data/default'
import { toDay, toCounterTime } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import QRCode from "qrcode"
import cfg from '@/common/conf'
import { user, isOperator, isEnVersion } from '@/data/token'
import i18n from '@/data/i18n/index'

export default {
  name: 'PileList',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('device.statuses.all'),
        value: -1
      },
      {
        label: t('device.statuses.enabled'),
        value: 1
      },
      {
        label: t('device.statuses.disabled'),
        value: 0
      }
    ]

    const render = {
      statuses,
      operators,
      release: [
        {
          label: t('g.yes'),
          value: true
        },
        {
          label: t('g.no'),
          value: false
        }
      ],
      updateStatuses: [
        {
          label: t('device.enable'),
          value: 1
        },
        {
          label: t('device.disable'),
          value: 0
        }
      ],
      alerts: [
        {
          label: t('device.statuses.all'),
          value: -1
        },
        {
          label: t('device.statuses.online'),
          value: 0
        },
        {
          label: t('device.statuses.offline'),
          value: 1
        }
      ],
      pileType: [
        {
          label: t('device.carPile.directCurrent'),
          value: 5
        },
        {
          label: t('device.carPile.altCurrent'),
          value: 6
        },
      ],
      shortcuts,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      slots: {},
      curData: {},
      valTime: '',
      valStatus: -1,
      valAlert: -1,
      dialogVisible: false,
      dialogQRCodeVisible: false,
      qrcodeMsg: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      curChargeRule: [],
      curChargeRuleStr: [],
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      pilesiteList: [],
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      user_info: {},
      is_opt: false,
      gun_list: [],
      cost_allocation: []
    })
    const func = {
      toCounterTime,
      addRule() {
        formData.curChargeRule.push({
          Id: 0,
          EboxId: 0,
          Price: "",
          MinPower: ""
        })
      },
      qrcode(row2, row) {
        formData.dialogQrcodeVisible = true;

        console.log(JSON.stringify(row));
        console.log(JSON.stringify(row2));

        //http://zldx.wxrule.smart2charge.com/device?id=CPZLDX0212XX202412060002&app=ylt&gunid=6C9C5EE6000201
        let QRCodeMsg = 'http://' + row.AgentCode.toLowerCase() + '.wxrule.smart2charge.com/device?id=' + row.DevId + '&app=ylt&gunid=' + row.MacAddr + '0' + row2.DevId;
        // return
        let opts = {
          errorCorrectionLevel: "H",//容错级别
          type: "image/png",//生成的二维码类型
          quality: 0.3,//二维码质量
          margin: 5,//二维码留白边距
          width: 300,//宽
          height: 300,//高
          text: QRCodeMsg,//二维码内容
          color: {
            dark: "#333333",//前景色
            light: "#fff"//背景色
          }
        };

        formData.qrcodeMsg = QRCodeMsg
        nextTick(() => {
          let msg = document.getElementById("qrcode");
          QRCode.toCanvas(msg, QRCodeMsg, opts, function (error) {
            console.log(error)
          });
        })



      },
      tapStatus(gunId, devId) {
        let title = '请确认是否解锁该桩？'
        console.log(JSON.stringify(gunId), 8203);
        console.log(devId);

        ElMessageBox.confirm(title, {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        })
          .then(({ value }) => {
            return axios.post("/admin/cpdevice/gununlock", {
              DevId: devId,
              GunId: gunId,
            })
          })
          .then(() => {
            ElMessage({
              type: 'success',
              message: t('g.success')
            })
            return func.loadData()
          })
          .catch(() => {

          })
      },
      async delRule(idx) {
        let tmp = []
        for (let i = 0; i < formData.curChargeRule.length; i++) {
          if (idx == i) {
            let item = formData.curChargeRule[i]
            if (item.Id) {
              //同步删除数据库
              await axios.delete(`/admin/device/charge/rule?id=` + item.Id, {
              })
            }
            continue;
          }
          tmp.push(formData.curChargeRule[i])
        }
        formData.curChargeRule = tmp

      },
      async showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        //func.expand(row, [1])
        formData.curData = row;
        console.log(row, '信息', row.BillSetInfo)
        if (row.BillSetInfo) {
          let BillBase = JSON.parse(row.BillSetInfo.BillBase)
          let BillTime = JSON.parse(row.BillSetInfo.BillTime)
          BillTime.forEach(element => {
            element.typeName = BillBase[element.type].label
          });
          formData.cost_allocation = BillTime
        }
        await func.get_gun(row.Id)
        formData.dialogDisplayVisible = true;

      },
      async get_gun(id) {
        let params = {
          eboxId: id,
        }
        let res = await axios.get('/admin/box/slot', {
          params
        })
        res.map(item => {
          item.UpdateAtStr = toDay(item.UpdateAt)
        })
        formData.gun_list = res
        console.log(res, '112121')
      },
      async toggleSlotStatus(item) {
        console.log("item", item)
        try {
          let res = await axios.post('/admin/device/disableSlot', {
            slotId: item.Id,
            disableInt: item.Status === 0 ? 0 : 1,
          })
          item.Status = 1 - item.Status
          ElMessage({
            type: 'success',
            message: t('g.success')
          })
        } catch (e) {
          console.log("err", e)
        }
      },
      onUploadBefore() {
        console.log("onUploadBefore")
      },
      onUploadError() {
        ElMessage({
          type: 'error',
          message: t('g.upload_failed')
        })
      },
      async expand(row, state) {
        if (state.length) {
          const res = await axios.get("/admin/box/slot?eboxId=" + row.Id)
          formData.slots[row.Id] = res
        }
        await func.loadCurChargeRule(row.Id)
      },
      onUploadSuccess(res) {
        console.log("上传结果", res)
        if (res.Code != 200) {
          return ElMessage({
            type: 'error',
            message: res.Msg
          })
        }
        const { id, url } = res.Data
        formData.curData[id] = url
      },
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.curData.agentPath = row.CodePath
        formData.curData.agentCode = row.AgentCode
        formData.curData.isrelease = row.IsRelease
        //func.loadCurChargeRule(row.Id)
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
        }
        formData.dialogVisible = true
      },
      async updateData() {
        console.log("updateOrCreate Data", formData.curData)
        if (typeof (formData.curData.Status) == 'undefined') {
          ElMessage({
            type: 'error',
            message: t("device.please_choose_status")
          })
          return
        }
        if (typeof (formData.curData.PileSiteId) == 'undefined') {
          ElMessage({
            type: 'error',
            message: '请选择站点'
          })
          return
        }

        try {
          const params = {
            id: formData.curData.Id,
            PileSiteId: formData.curData.PileSiteId,
            status: formData.curData.Status,
            agentPath: formData.curData.agentPath,
            isrelease: formData.curData.isrelease
          }
          await axios.put('/admin/box', params)
        } catch (e) {
        }
        formData.dialogVisible = false
        func.loadData(formData.curPage)
      },
      async loadCurChargeRule(eboxId) {
        formData.curChargeRule = []
        formData.curChargeRuleStr = []
        let res = await axios.get(`/admin/device/charge/rule`, {
          params: {
            eboxId
          }
        })
        formData.curChargeRule = res
        for (let idx = 0; idx < res.length; idx++) {
          let str = res[idx].Price + " " + t("device.price_unit")
          if (idx + 1 < res.length) {
            str += ` ( ${res[idx].MinPower} - ${res[idx + 1].MinPower}W)`
          } else {
            str += ` (` + t("device.great_than") + ` ${res[idx].MinPower} W)`
          }
          formData.curChargeRuleStr.push(str)
        }
      },
      async get_pilesite() {
        let params = {
          GetAll: 0,
        }
        let res = await axios.get(`/admin/pilesite/get`, {
          params
        })
        res.List.splice(0, 0, { Name: '无', Id: 0 })
        formData.pilesiteList = res.List
        console.log(formData.pilesiteList, '获取站点')
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
            type: 5,
          }
          if (formData.valStatus != -1) {
            params.status = formData.valStatus
          }
          if (formData.valAlert != -1) {
            params.alert = formData.valAlert
          }
          if (formData.valName) {
            params.name = formData.valName
          }
          if (formData.valCode) {
            params.code = formData.valCode
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          if (formData.valDevName) {
            params.devName = formData.valDevName
          }
          if (formData.valSiteName) {
            params.siteName = formData.valSiteName
          }
          let res = await axios.get(`/admin/box`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
      func.get_pilesite()
      if (localStorage.getItem('user_info')) {
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
        if (formData.user_info.RoleId == 14 || formData.user_info.RoleId == 25 || formData.user_info.RoleId == 18) {
          formData.is_opt = true
        }
      }
    })
    return {
      func,
      render,
      formData,
      uploadUrl: cfg.uploadUrl,
      token: user.token,
      isOperator,
      isEnVersion
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .charge-rule-box {
    >.title {
      display: flex;
      justify-content: space-around;
      font-size: 1.4rem;
      font-weight: bold;
    }

    >.content {
      >.item {
        display: flex;
        align-items: baseline;
        border: 1px solid #d9d9d9;
        padding: 0.25rem 0.625rem;
        border-radius: 0.3rem;
        margin-bottom: 0.625rem;

        .txt {}

        :deep(.el-input) {
          width: 6rem;
          margin: 0 1rem;
        }

        :deep(.el-button) {
          margin-left: 1rem;
        }
      }
    }
  }

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    .charge-range {
      margin-bottom: 1rem;
      font-weight: 700;
    }

    .slots {
      margin-top: 1rem;

      .slot {
        display: flex;
        margin-bottom: 0.5rem;

        >div {
          width: 10rem;

          &:first-child {
            width: 6rem;
          }
        }
      }
    }

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        min-width: 32%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>
