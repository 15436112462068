<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('decision.time') }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')"
            :clearable="true">
            <el-option v-for="item in render.chargeStatus" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>

        <div class="item">
          <span class="label">{{ $t('order.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valOrderSn"
            :placeholder="$t('order.inp.order_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDeviceId"
            :placeholder="$t('order.inp.device_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.site_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valSiteName"
            :placeholder="$t('device.site_name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('bill.nickname') }}</span>
          <el-input class="inp" size="small" v-model="formData.valTel" :placeholder="$t('bill.nickname')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table :data="formData.tableData" @row-click.self="func.showDetails" style="width: 100%"
        :summary-method="func.getSummaries" show-summary>
        <el-table-column prop="OrderSn" :label="$t('order.no')" width="200">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="80">
        </el-table-column>

        <el-table-column prop="UserInfo.Nickname" width="120" :label="$t('bill.nickname')">
        </el-table-column>
        <el-table-column prop="CPChargeOrderInfo.Account" width="170" :label="$t('card.number')">
        </el-table-column>
        <el-table-column prop="DeviceId" min-width="240" :label="$t('device.no')">
        </el-table-column>
        <el-table-column prop="CPChargeOrderInfo.GunId" min-width="60" :label="$t('order.carPile.gunNumber')">
        </el-table-column>
        <el-table-column prop="DeviceSiteName" min-width="120" :label="$t('device.site_name')">
        </el-table-column>
        <el-table-column width="120" :label="$t('member.status')">
          <template #default="scope">
            <el-tag :class="(scope.row.DeviceStatus == 1 || scope.row.DeviceStatus == 0) ? 'tag-fail' : 'tag-success'">
              {{ render.chargeStatus[scope.row.DeviceStatus].label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="110" :label="$t('order.carPile.initAmount')">
          <template #default="scope">
            {{ scope.row.StartMoney }}
          </template>
        </el-table-column>
        <el-table-column prop="allChargeFee" width="110" :label="$t('order.carPile.totalCost')">
          <template #default="scope">
            {{ scope.row.allChargeFee }}
          </template>
        </el-table-column>
        <el-table-column prop="chargePower" width="110" :label="$t('order.carPile.chargingCapacity')">
        </el-table-column>
        <el-table-column prop="chargeDuration" width="150" :label="$t('order.carPile.chargingTime')">
        </el-table-column>
        <el-table-column prop="CPChargeOrderInfo.InitSoc" width="80" :label="$t('order.carPile.InitSoc')">
        </el-table-column>
        <el-table-column prop="CPChargeOrderInfo.EndSoc" width="80" :label="$t('order.carPile.endSoc')">
        </el-table-column>

        <!-- <el-table-column prop="curPower" width="110" label="充电功率">
        </el-table-column>
        <el-table-column prop="curr" width="110" label="充电电流">
        </el-table-column>
        <el-table-column prop="volt" width="110" label="充电电压">
        </el-table-column> -->
        <el-table-column prop="CPChargeOrderInfo.StopCause" width="110" label="停止原因">
        </el-table-column>
        <el-table-column prop="startAtStr" width="180" label="开始时间">
        </el-table-column>
        <el-table-column prop="endAtStr" width="180" label="结束时间">
        </el-table-column>

        <!-- <el-table-column prop="chargePower" width="110" label="充电电量">
        </el-table-column> -->
        <el-table-column prop="CreatedAtStr" min-width="200" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" :label="$t('g.operator_ownership')"
          :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column width="190" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-button @click="func.tapStatus(scope.row, 1)" class="btn-success" type="primary">
              {{ $t('order.unlock') }}
            </el-button>
            <el-button v-if="scope.row.DeviceStatus === 1" @click="func.tapStatus(scope.row, 2)" class="btn-delete"
              type="primary">
              {{ $t('order.end') }}
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <div class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize }}
        </div>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" align-center>
      <el-descriptions border column="2">
        <el-descriptions-item :label="$t('order.no')">{{ formData.curData.OrderSn }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('statistics.user_id')">{{ formData.curData.UserInfo?.Id
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.UserInfo?.Nickname
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('card.number')">{{ formData.curData.CPChargeOrderInfo?.Account
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DeviceId }}</el-descriptions-item>

        <el-descriptions-item :label="$t('order.carPile.gunNumber')">{{ formData.curData.CPChargeOrderInfo.GunId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.site_name')">{{ formData.curData.DeviceSiteName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.state')">
          <el-tag
            :class="(formData.curData.DeviceStatus == 1 || formData.curData.DeviceStatus == 0) ? 'tag-fail' : 'tag-success'">
            {{ render.chargeStatus[formData.curData.DeviceStatus].label }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.initAmount')">{{ formData.curData.StartMoney
          }}元</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.totalCost')">{{ formData.curData.allChargeFee
          }}元</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.chargingCapacity')">{{ formData.curData.chargePower
          }}度</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.chargingTime')">{{ formData.curData.chargeDuration
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.chargingCosts')">{{ formData.curData.ChargeFee
          }}元</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.serviceFees')">{{ formData.curData.ServiceFee
          }}元</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.InitSoc')">{{
          formData.curData.CPChargeOrderInfo.InitSoc }}%</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.endSoc')">{{
          formData.curData.CPChargeOrderInfo.EndSoc
        }}%</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.stopCode')">{{
          formData.curData.CPChargeOrderInfo.StopCode }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.carVin')">{{
          formData.curData.CPChargeOrderInfo.VIN
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.carPile.stopReason')">{{
          formData.curData.CPChargeOrderInfo.StopCause }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.times.charge_start')">{{
          formData.curData.startAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.times.charge_end')">{{
          formData.curData.endAtStr
        }}</el-descriptions-item>

        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')">{{ formData.curData.NamePath }}</el-descriptions-item>
        <!-- <el-descriptions-item :label="$t('order.power_level')">{{
          formData.curData.ChargeBatteryOrderInfo.OriginPowerLevel
        }}W</el-descriptions-item> -->
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { operators, updateOperators, shortcuts, cascade_options_direct } from '@/data/default'
import { ElMessageBox, ElMessage } from 'element-plus'
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'OrderPile',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('member.statuses.all'),
        value: 2
      },
      {
        label: t('member.statuses.waiting'),
        value: 0
      },
      {
        label: t('member.statuses.charging'),
        value: 1
      },
      {
        label: t('member.statuses.completed'),
        value: 3
      },
      {
        label: t('member.statuses.failed'),
        value: 99
      }
    ]
    const chargeStatus = [
      {
        label: t('order.carPileStatus.starting'),
        value: 0
      },
      {
        label: t('order.carPileStatus.Charging'),
        value: 1
      },
      {
        label: t('order.carPileStatus.ChargingEnd'),
        value: 2
      },
      {
        label: t('order.carPileStatus.chargingRecordUpload'),
        value: 3
      },
    ]
    const render = {
      shortcuts,
      statuses,
      operators,
      cascade_options_direct,
      chargeStatus
    }
    const formData = reactive({
      tableData,
      curData: {},
      valOperator: 0,
      valStatus: '',
      valTime: '',
      valTel: '',
      valOrderSn: '',
      dialogDisplayVisible: false,
      valDeviceId: '',
      valSiteName: '',
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1,
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      IsStartWith: 1
    })
    const func = {
      getSummaries(options) {
        // return
        const { columns, data } = options;
        const sums = [];
        columns.forEach((column, index) => {

          if (index === 0) {
            sums[index] = t("g.total")
            return;
            //index === 22 || index === 24 || index === 28  这是表格第几列需要合计值
            // 例如只需要第3列的值求和，index===3
          } else if (index === 8) {
            const values = data.map((item) => Number(item[column.property]));
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              // console.log(JSON.stringify(value),23980);
              if (!isNaN(value)) {
                return Math.floor((prev + curr) * 100) / 100;
              } else {
                return prev;
              }
            }, 0) + t('card.currency');
          }
        });
        return sums;
      },
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      tapStatus(data, type) {
        // const flag = data.ChargeBatteryOrderInfo.ChargeStatus != 3
        // if (!flag) {
        //   return
        // }
        console.log(JSON.stringify(data), 23980);
        let title = ''
        if (type === 2) {
          title = '请确认是否结束该订单？'
        } else if (type === 1) {
          title = '请确认是否解锁该桩？'
        }
        ElMessageBox.confirm(title, {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        })
          .then(({ value }) => {
            return axios.post("/admin/cpdevice/endcharge", {
              orderSn: data.OrderSn,
            })
          })
          .then(() => {
            ElMessage({
              type: 'success',
              message: t('g.success')
            })
            return func.loadData()
          })
          .catch(() => {

          })
      },
      search() {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh() {
        formData.valOperator = 0
        formData.valStatus = 2
        formData.valTime = ''
        formData.valTel = ''
        func.loadData(1)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valOrderSn) {
            params.orderSn = formData.valOrderSn
          }
          if (formData.valDeviceId) {
            params.deviceId = formData.valDeviceId
          }
          if (formData.valSiteName) {
            params.siteName = formData.valSiteName
          }
          if (formData.valPhone) {
            params.phoneNum = formData.valPhone
          }
          if (formData.valOperator) {
            params.codePath = formData.valOperator
          }
          if (formData.valTel) {
            params.nickname = formData.valTel
          }
          if (formData.valStatus || formData.valStatus === 0) {
            params.deviceStatus = formData.valStatus
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          params.IsStartWith = formData.IsStartWith
          let res = await axios.get(`/admin/order/cpcharge`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.startAtStr = item.CPChargeOrderInfo.StartTime ? toDay(item.CPChargeOrderInfo.StartTime) : ''
            item.endAtStr = item.CPChargeOrderInfo.EndTime ? toDay(item.CPChargeOrderInfo.EndTime) : ''
            item.chargeDuration = func.formatSecToStr(item.CPChargeOrderInfo.ChargeTime)
            item.allChargeFee = (item.CPChargeOrderInfo.AllFee / 1000).toFixed(2)
            item.ServiceFee = (item.CPChargeOrderInfo.ServiceFee / 1000).toFixed(2)
            item.ChargeFee = (item.CPChargeOrderInfo.ChargeFee / 1000).toFixed(2)
            item.StartMoney = (item.CPChargeOrderInfo.StartMoney / 1000).toFixed(2)
            item.chargePower = item.CPChargeOrderInfo.ChargeEnergy / 1000
            item.remailMoney = (item.RemMoney / 1000).toFixed(2)
            item.volt = item.CPChargeOrderInfo.Volt / 1000 + 'V'
            item.curr = item.CPChargeOrderInfo.Curr / 1000 + 'A'
            // this.data.curPower = res.GunInfo.ChargePower
            item.curPower = ((item.CPChargeOrderInfo.Volt * item.CPChargeOrderInfo.Curr) / 1000000000).toFixed(2) + 'kw'
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      },
      formatSecToStr(seconds) {
        let daySec = 24 * 60 * 60;
        let hourSec = 60 * 60;
        let minuteSec = 60;
        let dd = Math.floor(seconds / daySec);
        let hh = Math.floor((seconds % daySec) / hourSec);
        let mm = Math.floor((seconds % hourSec) / minuteSec);
        let ss = seconds % minuteSec;
        if (dd > 0) {
          return dd + "天" + hh + "小时" + mm + "分钟";
        } else if (hh > 0) {
          return hh + "小时" + mm + "分钟" + ss + "秒";
        } else if (mm > 0) {
          return mm + "分钟" + ss + "秒";
        } else if (ss > 0) {
          return ss + "秒";
        }
      },
    }
    onMounted(() => {
      console.log("onMounted")
      //updateOperators()
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {

  .inp-block {
    display: block;
  }

  .table-expand {
    padding-left: 4rem;
    padding-top: 1rem;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }
}
</style>
