<template>
  <div class="content">

    <div class="card-box data-table">
      <div class="inp-list">
        <!-- <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div> -->
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>

        <div class="item">
          <span class="label">{{ $t('device.status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
        <el-button @click="func.showAddDataDialog" type="primary" class="btn-add">{{ $t('g.added') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table @row-click.self="func.showDetails" :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="Id" width="60">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('h5.name')" width="140">
        </el-table-column>
        <el-table-column prop="AgentCode" :label="$t('g.agent_code')" v-if="!isOperator()" width="80">
        </el-table-column>

        <el-table-column prop="ExpireTimeStr" :label="$t('h5.expireTime')" width="230">
        </el-table-column>
        <el-table-column width="100" :label="$t('expenses.status')">
          <template #default="scope">
            <el-tag :class="scope.row.Status == 1 ? 'tag-success' : 'tag-fail'">
              {{ scope.row.Status == 1 ? $t('expenses.statuses.enabled') : $t('expenses.statuses.disabled') }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="MaxUserNum" :label="$t('h5.MaxUserNum')" width="100">
        </el-table-column>
        <el-table-column prop="RealUserNum" :label="$t('h5.realUserNum')" width="100">
        </el-table-column>
        <el-table-column prop="EboxId" :label="$t('workOrder.cabinet_ID')" width="250">
        </el-table-column>
        <el-table-column prop="EboxType" :label="$t('order.combo_type')" width="100">
          <template #default="scope">
            {{ scope.row.EboxType > 0 ? render.devTypes[scope.row.EboxType - 1].label : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="Remark" :label="$t('order.remark')" width="100">
        </el-table-column>

        <el-table-column prop="Language" :label="$t('h5.Language')" width="120">
          <template #default="scope">
            {{ render.langTypes[scope.row.Language].label }}
          </template>

        </el-table-column>
        <el-table-column prop="WebUrl" :label="$t('h5.webUrl')" width="100">
        </el-table-column>
        <el-table-column prop="CodePath" :label="$t('g.operator_ownership')" :show-overflow-tooltip='true'
          min-width="230">
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column width="250" :label="$t('g.operation')" fixed="right" v-if="isPath()">
          <template #default="scope">
            <el-button @click="func.delete(scope.row.Id)" class="btn-delete" type="primary">{{ $t('g.delete')
              }}</el-button>
            <el-button @click="func.qrcode(scope.row)" class="btn-qrcode" type="primary" style="margin-left: 10px;">{{
              $t('g.QRCode')
            }}</el-button>
            <el-button @click="func.edit(scope.row)" class="btn-edit" type="primary" style="margin-left: 10px;">{{
              $t('g.edit')
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="80%" align-center>
      <el-descriptions border>
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('h5.name')">{{ formData.curData.Name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('h5.MaxUserNum')">
          {{ formData.curData.MaxUserNum }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('h5.realUserNum')">{{ formData.curData.RealUserNum }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.combo_type')"> {{
          formData.curData.EboxType > 0 ? render.devTypes[formData.curData.EboxType - 1].label : ''
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.remark')">{{
          formData.curData.Remark }}</el-descriptions-item>
        <el-descriptions-item :label="$t('h5.expireTime')">{{ formData.curData.ExpireTimeStr }}</el-descriptions-item>

        <el-descriptions-item :label="$t('h5.Language')"> {{ render.langTypes[formData.curData.Language].label
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('expenses.status')"> <el-tag
            :class="formData.curData.Status == 1 ? 'tag-success' : 'tag-fail'">
            {{ formData.curData.Status == 1 ? $t('expenses.statuses.enabled') : $t('expenses.statuses.disabled') }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>

      </el-descriptions>
      <el-descriptions border>
        <el-descriptions-item :label="$t('h5.webUrl')">{{
          formData.curData.WebUrl }}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogVisible" width="70%">
      <el-form label-width="100px" :model="formData.curData">
        <div class="el-form-row line-3">
          <el-form-item :label="$t('rights.agent')" v-if="formData.is_opt">
            <el-cascader @change="func.opt_operator" style="width: 100%;" :clearable="true"
              v-model="formData.curData.agentCode" :props="formData.props2" :options="render.cascade_options_direct" />
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="formData.curData.Name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('expenses.status')">
            <el-select class="inp" v-model="formData.curData.Status" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('g.operator')">

            <el-cascader style="width: 100%;" :clearable="true" v-model="formData.curData.CodePath"
              :props="formData.props" :options="render.cascade_options_direct" />
          </el-form-item>
          <el-form-item label="机柜">
            <!-- <el-input v-model="formData.curData.EboxId">
            </el-input> -->
            <el-select v-model="formData.curData.EboxId" filterable remote reserve-keyword
              :remote-method="func.getEboxData">
              <el-option v-for="item in formData.eboxData" :key="item.value" :label="item.value" :value="item.value">
                {{ item.value }}--{{ item.label }}
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item :label="$t('h5.webUrl')">
            <el-input v-model="formData.curData.WebUrl">
            </el-input>
          </el-form-item> -->
          <el-form-item label="最大用户数">
            <el-input v-model="formData.curData.MaxUserNum">
            </el-input>
          </el-form-item>
          <el-form-item label="截止有效时间">
            <el-date-picker class="inp" v-model="formData.curData.ExpireTime" type="datetime" value-format="X"
              placeholder="选择日期时间">
            </el-date-picker>
            <!-- <el-input v-model="formData.curData.ExpireTime">
            </el-input> -->
          </el-form-item>
          <el-form-item label="默认语言版本">
            <el-select class="inp" v-model="formData.curData.Language" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.langTypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('expenses.type')">
            <el-select class="inp" v-model="formData.curData.EboxType" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.devTypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="formData.curData.Remark">
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="上传图片">
            <el-upload class="avatar-uploader" action="/admin/device/ImgsUp" list-type="picture-card" method="post"
              :limit="3" :headers="{ Authorization: token }" :http-request="(params) => func.onUpload(params, 1)"
              :show-file-list="false" style="margin-left: 20px;">
              <img v-if="formData.dataImg.Img1" :src="formData.dataImg.Img1" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item> -->
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog class="qrcode" v-model="formData.dialogQrcodeVisible" align-center>
      <div>{{ formData.qrcodeMsg }} </div>
      <canvas id="qrcode" style="width:300px;height:300px;border:1px solid black;margin-top: 20px;"></canvas>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, watch } from 'vue'
import { operators, updateOperators, shortcuts, operatorList, cascade_options_direct } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/data/i18n/index'
import { isOperator, isPath, isAdmin } from '@/data/token'
import QRCode from "qrcode"
import { nextTick } from 'vue'

export default {
  name: 'ExpensesCombo',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('expenses.statuses.all'),
        value: -1
      },
      {
        label: t('expenses.statuses.enabled'),
        value: 1
      },
      {
        label: t('expenses.statuses.disabled'),
        value: 0
      }
    ]
    const devTypes = [
      {
        label: t('h5.dev_type.t0'),
        value: 1
      },
      {
        label: t('h5.dev_type.t1'),
        value: 2
      },
      {
        label: t('h5.dev_type.t2'),
        value: 3
      },
      {
        label: t('h5.dev_type.t3'),
        value: 4
      },
      {
        label: t('h5.dev_type.t4'),
        value: 5
      }
    ]

    const render = {
      operators,
      statuses,
      devTypes,
      langTypes: [
        {
          label: '所有',
          value: 0
        },
        {
          label: '中文',
          value: 1
        },
        {
          label: '英文',
          value: 2
        },

      ],

      updateOperatorsList: operatorList,
      updateStatuses: [
        {
          label: t('expenses.enable'),
          value: 1
        },
        {
          label: t('expenses.disable'),
          value: 0
        }
      ],
      shortcuts,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valStatus: -1,
      valType: 0,
      dialogVisible: false,
      dialogDisplayVisible: false,
      dialogQrcodeVisible: false,
      curPage: 1,
      pageSize: 10,
      dataImg: {
        Img1: ''
      },
      total: 500,
      valOperator: 0,
      devType: [],
      eboxData: [],
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      IsStartWith: 1,
      is_opt: false
    })
    watch(() => formData.curData.CodePath, () => {
      //let simplerWay = formData.curData.agentPath.slice(0, -1).split('/').pop(); 
      func.getDevType(formData.curData.CodePath)
    })
    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      async getEboxData(query) {
        if (query === '' || !formData.curData.CodePath) {
          formData.eboxData = [];
        } else {
          setTimeout(async () => {
            let res = await axios.get('/admin/box', {
              params: {
                devName: query,
                code: formData.curData.CodePath
              }
            })
            if (res.List.length == 0) {
              formData.eboxData = []
            } else {
              formData.eboxData = res.List.map((item) => {
                return {
                  value: item.DevId,
                  label: item.SiteName
                }
              })
              console.log(JSON.stringify(formData.eboxData));
            }
          }, 200);



        }
      },
      qrcode(row) {
        formData.dialogQrcodeVisible = true;

        // console.log(JSON.stringify(row));
        let QRCodeMsg = row.WebUrl;
        // return
        let opts = {
          errorCorrectionLevel: "H",//容错级别
          type: "image/png",//生成的二维码类型
          quality: 0.3,//二维码质量
          margin: 5,//二维码留白边距
          width: 300,//宽
          height: 300,//高
          text: QRCodeMsg,//二维码内容
          color: {
            dark: "#333333",//前景色
            light: "#fff"//背景色
          }
        };

        formData.qrcodeMsg = QRCodeMsg
        nextTick(() => {
          let msg = document.getElementById("qrcode");
          QRCode.toCanvas(msg, QRCodeMsg, opts, function (error) {
            console.log(error)
          });
        })



      },
      async delete(Id) {
        ElMessageBox.confirm(t('g.sure_delete'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(() => {
          try {
            axios.post('/admin/webshow/del', {
              Id
            }).then((res) => {
              if (res == '删除成功') {
                ElMessage({
                  type: 'success',
                  message: t('g.success')
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: t("err_code.20005")
                })
              }
              func.loadData()
            })

          } catch (error) {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }

        })
      },
      async getDevType(CodePath) {
        let params = {}
        params.CodePath = CodePath
        params.getAll = 1
        let res = await axios.get('/admin/box/devtypes', { params })
        if (res) {
          formData.devType = res
        }
      },
      async showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.curData = row;
        formData.dialogDisplayVisible = true;
      },
      edit(row, column, e) {
        formData.curData = row
        if (formData.curData.DevType) {
          formData.curData.DevTypeStr = formData.curData.DevType.split('|')
        }
        formData.curData.CodePath = row.CodePath
        formData.curData.agentCode = row.AgentCode
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {}
        // formData.curData = {
        //   CodePath: operatorList.length ? operatorList[0].value : "",
        // }
        formData.curData.Type = 1
        formData.dialogVisible = true
      },
      async updateData() {
        // console.log("updateOrCreate Data",formData.curData)
        // if (typeof (formData.curData.Type) == 'undefined') {
        //   ElMessage({
        //     type: 'error',
        //     message: t('expenses.tips.type')
        //   })
        //   return
        // }
        try {
          let params = {
            Name: formData.curData.Name,
            MaxUserNum: formData.curData.MaxUserNum,
            Status: formData.curData.Status,
            Language: formData.curData.Language,
            EboxId: formData.curData.EboxId,
            EboxType: formData.curData.EboxType,
            Remark: formData.curData.Remark,
          }
          if (formData.curData.CodePath) {
            params.CodePath = formData.curData.CodePath
          }
          if (formData.curData.agentCode) {
            params.AgentCode = formData.curData.agentCode
          }
          if (formData.curData.ExpireTime) {
            params.ExpireTime = formData.curData.ExpireTime
          }
          // if (formData.curData.WebUrl) {
          //   params.WebUrl = formData.curData.WebUrl
          // }
          if (formData.curData.Id) {
            params.Id = formData.curData.Id
            await axios.post('/admin/webshow/edit', params)
          } else {
            if (formData.curData.agentCode && formData.curData.EboxId) {
              params.WebUrl = 'http://' + formData.curData.agentCode.toLowerCase() + '.wxrule.smart2charge.com/device?id=' + formData.curData.EboxId + '&h5Id=';
            }
            await axios.post('/admin/webshow/add', params)
          }
        } catch (e) {

        }
        formData.dialogVisible = false
        func.loadData()
      },
      opt_operator() {
        formData.curData.DevTypeStr = ''
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valStatus != -1) {
            params.Status = formData.valStatus
          }
          if (formData.valOperator) {
            params.CodePath = formData.valOperator
          }

          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/webshow/get`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.ExpireTimeStr = item.ExpireTime != 0 ? toDay(item.ExpireTime) : ''
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
      func.getDevType()
      if (localStorage.getItem('user_info')) {
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
        if (formData.user_info.RoleId == 14 || formData.user_info.RoleId == 25 || formData.user_info.RoleId == 18) {
          formData.is_opt = true
        }
      }
    })
    return {
      func,
      render,
      isOperator,
      isAdmin,
      formData,
      isPath
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;


        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 33%;
    }

    .inp {
      width: 100%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}
</style>
