export default {
  test:"test",
  appname:"Dandelion Management System",
  tips:'tips',
  confirm:"confirm",
  cancel:"cancel",
  err_code:{
    201:"login failure",
    523:"user not registered",
    530:"please log in",
    531:"Please purchase or renew the package first",
    580:"There is an unpaid charge order, please pay first",
    581:"Support up to three charging orders",
    700:"The balance needs to be greater than $5 before charging",
    20000:"System error",
    20001:"json conversion error",
    20002:"This user has not yet bind a battery",
    20003:"Incoming parameter error",
    20004:"Failed to add data",
    20005:"Modification Failed",
    20006:"There are still menus or operations under the current module,cannot be deleted",
    20007:"Please input id",
    20008:"Please fill in the correct merchant number",
    20009:"This agent does not bind background users ",
    20010:"Please select Upload File",
    20011:"Uploading files is not valid",
    20012:"Directory creation failed",
    20013:"Battery creation failed",
    20014:"Charging cabinet creation failed",
    20015:"Data does not Exist",
    20016:"Adding failed, this type of package already exists",
    20017:"Creation Failed",
    20018:"This package cannot be found",
    20019:"Illegal Request",
    20020:"User name or password length is not valid",
    20021:"Data Already Exists",
    20022:"Password length is not valid, password length cannot be less than 6 characters",
    20023:"Special user cannot be deleted",
    20024:"This user is not bound to a permission list",
    20025:"Image suffix is not valid",
    20026:"This user does not bind a battery",
    20027:"User has not purchased the package",
    20028:"This agent equipment cannot be used",
    20029:"Device alarm, temporarily unavailable",
    20030:"Device disabled, temporarily unavailable",
    20031:"Package does not exist",
    20032:"Balance is insufficient",
    20033:"Deposit not set",
    20034:"Failed to deduct user balance",
    20035:"Failed to save user consumption log",
    20036:"Enough to buy a package, can not buy different types of packages",
    20037:"This battery deposit order does not exist",
    20038:"Wechat Pay initialization failed",
    20039:"No valid battery exists for this user",
    20040:"Device temporarily unavailable, unable to end charging",
    20041:"Non-maintenance personnel may not perform this operation",
    20042:"Wechat Pay callback failed",
    20043:"The time has not expired and cannot be deleted!",
  },
  chart:{
    keys:{
      "离线":"Offline",
      "缺电":"Lack of electricity",
      "锁仓":"Lock slot",
      "充满":"Fully charged",
      "闲置":"idle",
      "充电中":"Charging",
      "故障停用":"Fault off",
    },
    turnover_types:{
      wallet_charge:"Rechare",
      wallet_consume:"Consume",
      deposit:"Deposi",
      setmenu:"Power change package",
    },
    max_value:"Max",
    today:"Today",
    yesterday:"Yesterday",
    site_user_trend:"Network user Trend! Code scanning volume",
    num:"Amount",
    trends:{
      today:'Trend Today丨Live',
      turnover:"Trend Turnover",
      ebox:"Power exchange cabinet order cycle trend",
      cbox:"Charging Cabinet order cycle trends",
      pile:"Charging pile order cycle trends",
      consume:"trends in consumption",
    },
    order_ratio:"Order Ratio",
    order_types:{
      rent:"Rent",
      return:"Return",
      exchange:"Exchange ",
      cbox:"CBox",
      pile:"Pile",
      charge:"Recharge",
      setmenu:"SetMenu",
    },
    order_status:"Order status | real-time",
    order_statuses:{
      doing:"Doing",
      error:"Error",
      finished:"Finished",
    },
    devs_status:{
      ebox:"Power exchange cabinet equipment status | real-time",
      cbox:"Charging cabinet equipment status | real-time",
      pile:"Charging pile equipment status | real-time",
      cpbox:"hybrid equipment status | real-time",
    },
    dev_charge_status:{
      ebox:'Charging status of power exchange cabinet | real-time',
      cbox:"Charging status of charging cabinet | real-time",
      pile:"Charging status of charging pile | real-time",
      cpbox:"Hybrid device charging status | real-time",
    },
    unit_times:"Times",
    devs_rank:{
      err:"High frequency fault ranking | Top 30",
      offline:"High-frequency network disconnection ranking | Top 30",
      locked:"High-frequency lock-up slot rankings | Top 30",
      open:"Open position anomaly rankings | Top 30",
      charge:"Charging anomaly ranking | Top 30",
      frequence_high: "high-frequency equipment | Top 30",
      frequence_low:"low frequency use equipment | Top 30",
      lack:"Lack of electric cabinet group | Top 30",
    },
  },
  market:{
    color:{
      explain:"Color represents",
      types:{
        red:"Red",
        orange:"Orange",
        blue:"Blue",
      },
      means:{
        red:"High frequency",
        orange:"Medium frequency",
        blue:"Low frequency",
      }
    }
  },
  menu:{
    home:"Home",
    big_screen:"Large screen display",
    big_data:"Big Data",
    decision:{
      analyze:"Data Analysis",
      hot:"Hot Map",
      market:"Market Analysis",
      operation:"Operation Analysis",
      properties:"Asset Analysis",
      order:"Order Analysis",
      user:"User Analysis",
      statistics:"Asset Income",
    },
    distribution_site:"Sites Distribution",
    distribution_battery:"电池分布",
    finance:"Finance",
    meituan:"Meituan",
    expenses_center:"Expenses",
    set_menu_manage:"Package Settings",
    bill_manage:"Bill",
    turnover_record:"Turnover",
    settle:"Settle",
    refund:"Refund",
    badbill:"BadBill",
    refund_record:"Refund Record",
    workOrder:"Work Order",
    devManage:"Device",
    operationManage:"Operations",
    workOrderList:"Work Order List",
    workOrderSet:"Work Order Set",
    orders:{
      manage:"Order",
      rent:"Battery Rent Order",
      exchange:"Swapping Battery Order",
      return:"Returning Battery Order",
      cbox:"Charging Cabinet Order",
      pile:"Charging Pile Order",
      carPile:"Car Pile Order",
      setmenu:"Battery Swapping Package",
      recharge:"Recharge Order",
      offline:"Offline card purchase order",
      temporary_record:"Temporary Record",
      import_record:"Import Record",
    },
    operation:"Operation",
    member:"Member",
    profile:"Profile",
    member_list:"Members",
    agent_list:"Agents",
    operator_list:"运营商管理",
    after_sale_service:"After-Sale service",
    open_solot_record:"Open Slot Record",
    open_solot:"Open Slot Record",
    electricity_stat:"Electricity Bill Statistics",
    alert_record:"Alert Record",
    activity_manage:"Event",
    recruit_manage:"Invit-Users manage",
    discount_coupon:'Coupon',
    award_manage:"Get the list",
    recruit_new:"Inviting New Users ",
    announcement_list:"Announcement",
    withdraw:"Withdrawal",
    restriction_rule:"Rule restrictions",
    feedback:"Feedback",
    sys:"System",
    log_info:"Logs",
    log_sys:"System logs",
    log_api:"Device logs",
    msg_log:"Msg logs",
    properties:"Properties",
    billStrategy:"Billing strategy",
    siteMange:"Site Management",
    h5Mange:"h5 Management",
    box_manage:"Cabinet",
    ebox_list:"Swapping cabinets",
    cbox_list:"Charging cabinet",
    cpbox_list:"Mixed cabinets",
    pile_list:"Piles",
    car_pile_list:"Car Pile",
    card_list:"Cards",
    pile_manage:"Pile",
    battery_manage:"Battery",
    card_manage:"Swipe Card",
    battery_list:"Batteries",
    rights_manage:"Authority",
    rights_set:"Authority Settings",
    managers:"Managers",
    role_manage:"Roles",
    ExpensesBeOverdue:'Overdue',
    overdue:"Overdue records",
    agents_switch:'Control',
    SecondTierAgent:"First tier operator",
    ThirdTierAgent:"Second tier operator",
    FourthTierAgent:"Third tier operator",
  },
  common:{
    popup_titles:{
      t0:"Latest User Feedback",
      t1:"Major Alarm",
      t2:"Latest Order",
    },
    no_content:"No Content",
    rights:"© 2021 Shenzhen Modiary Co., Ltd.. All Rights Reserved",
    days:"D",
    hours:"H",
    minutes:"M",
    seconds:"S",
    not_support:"Not Support",
    coord_errs:{
      t0:"You refuse to share location information",
      t1:"Location information not available",
      t2:"Get location information timeout",
    },
    all:"All",
    out_of_sync:"Out of Sync.",
    dates:{
      last_week:"Last Week",
      last_month:"Last Month",
      last_two_month:"Last 2 Month",
      last_quarter:"Last 3 Month",
      last_six_month:"Last 6 Month",
      last_year:"Last Year",
      future_week:"Next Week",
      future_month:"Next Month",
      future_quarter:"Next Quarter",
    },
  },
  g:{
    loading:"loading...",
    QRCode:"QR Code",
    sure_delete:"Are You Sure To Delete?",
    to:"To",
    start_date:"Start Date",
    end_date:"End Date",
    sum:"Total",
    currency:'￥',
    unit_num:"Total",
    per_page:"Each",
    see:"Search",
    total:"Total",
    minute:"Minute",
    operation:"Operate",
    search:"Search",
    clear:'Clear',
    abnormal:'异常',
    year:'year',
    month:'月',
    btn_confirm:"Confirm",
    btn_cancel:"Cancel",
    condition:"Condition",
    create_at:"Created At",
    update_at:"Updated At",
    battery_update_at:"Battery update time",
    name:"Name",
    operator:"Operator",
    agent_code:"Agent Code",
    choose:"Please Choose",
    success:"Success",
    upload_failed:"Upload Failed",
    please_input:"please enter ",
    residue_degree:'Residue Degree',
    residue_days:'Remain Days',
    yes:"Yes",
    open:"Open",
    close:"Close",
    no:"No",
    fei:'非',
    null:'Null',
    detail:"Detail",
    edit:"Edit",
    delete:"Delete",
    ms:"Background Management System",
    battery_status:"Battery status",
    stage:'temporary storage',
    stage_day:'Cumulative temporary storage days',
    staging_day:'Number of days in temporary storage',
    stage_month_num:'Number of temporary storages',
    operator_ownership:'Operator ownership',
    export:'Export',
    Success:"Success",
    Failed:"Fail",
    total:'total',
    date:'date',
    state:'state',
    added:'Added',
    yuan:'yuan',
    day:'day',
    name:'Name',
    enter_name:'Please enter your name',
  },
  home:{
    onlineCab:'Online Cabinet',
    offlineCab:'Offline Cabinet',
    onlineBat:'Online Battery',
    offlineBat:'Offline Battery',
    dataStat:'Data Statistics',
    commonFunctions:'Common Functions',
    bill:'Bills',
    more:'More',
    sevenDay:'Last 7 Days',
    oneMonth:'Last Month',
    income:'Income',
    newUser:'New Tenants',
    batteryReplace:'Replace Battery',
    comboNum:'Combo',
    battayReturn:'Return Battery',
    userManage:'User',
    cabManage:'Cabinet',
    coupons:'Coupons',
    battayReturnList:'Bat-replace',
    newRentList:'Bat-rental',
    feedback:'Feedback',
    refundReview:'Refund Review',
    combo:'Combo',
    topUp:' TopUp ',
    pay:' Pay ',
    charge:'Charge',
    Refund:' Refund ',
    deposit:'Deposit',
    fine:'Fine',
    overduefines:'Overdue fines',
  },
  profile:{
    roleDes:'Role Description',
    lastLoginTime:'Last login time',
    curLoginTime:'Current login time',
    personalDetails:'Personal Details',
  },
  energy:{
    num:'Monthly electricity consumption',
    price:'Electricity price for the month',
    fee:'Monthly Fees',
  },
  rights:{
    please_choose_deparment:"Please Choose Deparment",
    is_mch:"Whether it is a directly connected merchant",
    name:"Name",
    description:"Description",
    username:"Username",
    role:"Role",
    password:"Password",
    ph_psw:"If do not modify, do not fill",
    department:"Department",
    module_parent:"Parent Module",
    module_name:"Module Name",
    module_address:"Module Address",
    module_top:"---Top Module---",
    rights:"Rights",
    agent:"Agents",
    site_manager:"Site Manager",
    dev_ids:"Device Id",
    dev_ids_tips:"Multiple separated by commas",
    effective_time:"Effective Date",
    choose_time:"Choose Expiration Date",
    belonging_role:"Belonging role",
    role_attribution:"Role attribution",
    superior_operator:"Superior operator",
    First_operatorname:"First level operator name",
    opt_operator1:"Please enter the name of the first tier operator within 6 characters",
    weChat_account:"WeChat Merchant Account",
    payment_weChat:"Payment related WeChat merchants",
    weChat_password:"WeChat merchant account password",
    no_need:"No need to modify or fill in",
    top_operators:"Top operators",
    opt_operator2:"Please select the top tier operator to which you belong",
    first_operator:"First tier operator",
    opt_operator3:"Please select the primary operator to which you belong",
    opt_operator4:"Please enter a second tier operator name within 6 characters",
    secondary_operator:"Secondary operator",
    opt_operator5:"Please select the secondary operator to which you belong",
    third_operatorname:"Third level operator name",
    opt_operator6:"Please enter the name of the third tier operator within 6 characters",
   
  },
  order:{
    remark:"Remark",
    unit_times:"Times",
    unit_months:"Months",
    combo_type:"Type",
    end:"End",
    input_end_reason:"Please Fill In Reason",
    name:"Nickname",
    no:"NO.",
    price:"Money",//这里应该翻译为money
    failed_reason:"Failed Reason",
    end_reason:"End Reason",
    slot_get:"Got Slot",
    power_level:"Power",
    slot_return:"Returned Slot",
    site_name:"Site Name",
    pay_way:"Payment",
    back_way:"Refund Ways",
    unpayed:"Unpaid",
    overdueDeduction:"Overdue-deduction",
    overdueDay:"Overdue deduction days",
    pilePosition:"Pile position",
    combo:{
      type:'Package Type',
      price:"Package Prices",
      actualAmount:"Actual amount paid",
      cashDiscount:"Cash deduction",
      couponDiscount:"Coupon discount",
      RefundAmount:"Refund amount",
    },
    ps:{
      //充电桩状态
      all:"All",
      unresponsive:"Unresponsive",
      charging:"Charging",
      full:"Full",
      finished:"Finished",
      depositing:"Depositing",
      prepare_failed:"Prepare Failed",
      failed:"Failed",
    },
    cs:{//充电柜状态
      all:"All",
      waiting:"Waiting",
      charging:"Charging",
      full:"Full",
      finished:"Finished",
      depositing:"Depositing",
      prepare_failed:"Prepare Failed",
      failed:"Failed",
      depositing_free:"Free Depositing",
    },
    took_battery:{
      no:"Number of taking out the battery",
      soc:"SOC of taking out the battery",
    },
    moneys:{
      charge:"Charge Price",
      deposit:"Deposit Price",
    },
    PaymentStatus:{
      name:"Pay Status",
    },
    carPile:{
      gunNumber:"Gun Number",
      initAmount:"Initial Amount",
      totalCost:"Total Costs",
      chargingCapacity:"Charging Capacity",
      chargingTime:"Charging Time",
      chargingCosts:"Charging Costs",
      serviceFees:"Service Fees",
      InitSoc:"Initial Soc",
      endSoc:"End Soc",
      stopCode:"Stop Code",
      carVin:"Car Vin",
      stopReason:"Stop Reason",
    },
    unlock:"Unlock",
    carPileStatus:{
      starting:"Starting",
      Charging:"Charging",
      ChargingEnd:"Charging End",
      chargingRecordUpload:"Charging Record Upload",
    },
    rentStatus:'Rent Status',
    event:'Event',
    ReturnBatteryVoltage:'Return battery voltage',
    TakeOutBatteryVoltage:'Take out the battery voltage',
    ReturnBatterySoc:'Return battery soc',
    deposit_duration:"Deposti Duration(Minute)",
    charge_duration:"Charge Duration(Minute)",
    slot_id:"Slot Id",
    times:{
      charge_start:"Charge Start At",
      charge_end:"Charge End At",
      took_battery:"Took Battery At",
    },
    returned_battery_no:"Returned Battery NO.",
    slot_battery_before:"Battery Before",
    failed_reasons:{
      t1:"No replaceable battery",
      t2:"Timeout for closing empty door",
      t3:"Not enough empty positions",
      t4:"Battery Insertion Timeout",
      t5:"The machine is busy",
      t6:"The device is offline",
      t7:"Battery insertion confirmation failed",
      t8:"Cabinet timeout",
    },
    pay_ways:{
      wechat:"Wechat",
      balance:"Balance",
      cash:'Cash',
      combo:'Combo',
      paypal:'Paypal',
      alipay:'Alipay',
      meiTuan:'MeiTuan',
    },
    returned:"Returned",
    imgs:{
      t0:"Image Open Empty Slot",
      t1:"Image Close Empty Slot",
      t2:"Image Open Battery Slot",
      t3:"Image Close Battery Slot",
    },
    inp:{
      name:"Please Input User Nickname",
      order_no:"Please Input Order NO.",
      device_no:"Please Input Device NO.",
    },
  },
  member:{
    cur_month_cost:"Cur Month Cost",
    cur_month_order_amount:"Cur Month Orders",
    month_card_expire:"Month Card About To Expire",
    expired_at:"Expired At",
    expired_day:"Expired Days",
    expire:"Package expires",
    all_expire:"Package expires",
    package_expired:"Package has expired",
    num_expired:'Number of expired packages',
    is_expire:"Package",
    reg_at:"Reg At",
    agent_name:"Agent Name",
    is_vip:"VIP",
    agent_name2:"Agent Name",
    agent_info:"Agent Info",
    IdNum:"Id number",
    IdCardimg:"ID photo",
    IdTime:"ID card validity period",
    IdName:"Name",
    wechat_rate:"Wechat Rate(%)",
    mobile:"Phone",
    email:"Email",
    remainingTime:"Remaining Time",
    verifySMS:"Verify SMS",
    temporaryState:"Temporary State",
    temporaryStorageTime:"Temporary Storage Time",
    activated:"Activated",
    cashYuan:"Cash(yuan)",
    cashDay:"Cash(day)",
    invited:"Invited",
    balanceAccount:"Balance Account(yuan)",
    notReceived:"Not Received(day)",
    totalInvitationAmount:"Total Invitation Amount(yuan)",
    clear_mobile:"Recheck phone",
    clear_mobile_success:"Clear phone successfully",
    clear_mobile_fail:"Failed to re-verify phone",
    confirm_clear_mobile:"Confirm to re-verify mobile number",
    res_mobile_fail:",Need to re-verify after clearing",
    status:"Status",
    recharge:"Balance Recharge",
    rechargeAmount:"Please enter the recharge amount:",
    is_release:"release",
    user_status:'User Status',
    statuses:{
      all:"All",
      success:"Success",
      completed:"Completed",
      failed:"Failed",
      charging:"Charging",
      waiting:"Waiting",
      returned:"Returned",
      unpay:"Unpay",
      paid:"Paid",
    },
    order_status:{
      name:'Package status',
      all:'All',
      rented:'Rented',
      overdue:'Overdue',
      new_rent:'New Rent',
      renew_lease:'Renew Lease',
      rent_out:'Rent Out',
    },
    battery_status:'Bind battery',
    agent_code:"Agent Code",
    badBatteryNum:'Old battery ID',
    goodBatteryNum:'New battery ID',
    unBindBatteryNum:'Unbind battery ID',
    levelname:"Level name",
    level:"Level",
    username:"Username",
    nickname:"Nickname",
    coupon_day:"Coupon(day)",
    coupon_money:"Coupon(yuan)",
    detail:"Detail",
    mchid:"WeChat merchant account",
    mchid2:"wechat Merchant Number (payment related)",
    mchid_zfb:"Alipay merchant number",
    mchid2__zfb:"Alipay merchant number(payment related)",
    password:"WeChat merchant password (do not need to be changed to blank)",
    new_password:"New Password",
    new_password_ph:"Please enter a new password",
    repassword:"Repeat",
    repassword_ph:"Repeat the new password",
    repassword_not_same:"Repeat Password Not Same",
    modify_password:"Modify Password",
    balance:"Balance",
    avatar:"Avatar",
    bind_battery:"Replace battery",
    more_actions:"More",
    unbind_battery:"Unbind battery",
    maintainer_set:"Common",
    level1_user:"First level maintenance account",
    level2_user:"Second level maintenance account",
    level3_user:"Third level maintenance account",
    deductionDays:"Deduction days",
    maintainer_unset:"Maintainer",
    forbidden:"Forbidden",
    overdue:"Overdue user",
    freeuser_set:"Free",
    freeuser_unset:"Not Free",
    bound_battery_no:"Bound Battery NO.",
    battery_no:"Battery NO.",
    err_money:"Wrong Money Number",
    input_battery_no:"Please Input Battery NO.",
    bind_success:"Bind Success",
    unbind_success:"unBind Success",
  },
  log:{
    trigger_time:"Trigger At",
    name_contain:"Name Contain",
    address_contain:"Address Contain",
    input_name:"Please Input Device Name",
    input_address:"Please Input Device Address",
    info:"Log Info",
    info_detail:"Log details",
    type:"Type",
    detailed_logs:"Detailed logs",
    types:{
      t0:"Power exchange cabinet Alarm",
      t1:"Power exchange cabinet failure",
      t2:"Charging Cabinet Alarm",
      t3:"Charging cabinet failure",
      t4:"Charging pile Alarm",
      t5:"Charging pile failure",
    },
    cabinet_disconnection:"Cabinet disconnection",
    battery_disconnection:"Battery disconnection",
    door_malfunction:"Warehouse door malfunction",
    message:"Message",
    maintain_warehouse:"Maintain warehouse opening",
    battery_shortage:"Battery shortage",
    replace_battery:"Replace and bind the battery",
    empty_warehouse:"Fake empty warehouse",
  },
  decision:{
    type:"Type",
    cycle:"Cycle",
    time:"Time",
    poi:"POI",//地图poi
    stat_condition:"Condition",
    input_name:"Please Input Name",
    active_user:"Active User Amount",
    able_user:"Able User Amount",
    rent_income:"租电收益",
    combo_income:"套餐收益",
    charge_income:"充值收益",
    all_income:"总收益",
    tips:{
      type:"Please Select",
      status:"Please Select",
      num:"Please Input Amount",
      price:"Please Input Price",
      agent_code:"Please Select Agent",
      correct_price:"Please Input Correct Price",
      correct_num:"Please Input Correct Number",
    },
    types:{
      distribution_device:"Devices Distribution",
      distribution_member:"Users Distribution",
      distribution_order:"Orders Distribution",
    },
    cycles:{
      by_year:"By Year",
      by_month:"By Month",
      by_day:"By Day",
    },
  },
  expenses:{
    name:"Name",
    type:"Type",
    num:"Amount",
    num_explain:"Quantity (corresponding to the number of months or times)",
    price:"Price",
    status:"Status",
    enable:"Enale",
    disable:"Disable",
    combo_code:'Combo Code',
    statuses:{
      all:"All",
      enabled:"Enabled",
      disabled:"Disabled",
    },
    types:{
      all:"All",
      by_month:"By Month",
      by_times:"By Times",
      by_day:"按天",
    },
  },
  bill:{
    total_charge:"Total Charge",
    total_consume:"Total Consume",
    total_deposit:"Total Deposit",
    sure_refund:"Are you sure you want a refund?",
    status:"Status",
    type:"Type",
    types:{
      all:"All",
      rent:"Rent order",//账单管理 => 流水记录
      cbox:"Electrical Cabinet charging Order",
      ebox:"Replacement Order",
      pile:"Charging pile Order",
      charge:"Prepaid Order",
      combo:"Purchase Order for Package",
      return:"Power return order",
      deposit:"Charging order deposit fee",
    },
    logTypes:{
      consume:"Consume",
      charge:"Charge",
      deposit:"Deposit",
    },
    refund_types:{
      deposit:"Deposit",
      balance:"Balance",
      bad_bill:"Bad Bill"
    },
    user:"User",
    name:"Name",
    nickname:"Nickname",
    user_nickname:"Nickname",
    operator:"Operator",
    opt_money:"Money",
    remark:"Reason",
    record_add:"Add",
    total:"Total",
    user_id:"User Id",
    no:"NO.",
    money:"Money",
    refunded:"Refuned",
    refund:"Refund",
    refund_money:"Refund Money",
    refunded_money:"Refunded Money",
    bill_date:"Bill Date",
    settle_count:"Settle Amount",
    money_received:"Money Received",
    money_bad_bill:"Money Bad Bill",
    settle_rate:"Settle Rate",
    money_settle:"Settle Money",
    rs:{
      all:"All",
      waiting:"Waiting",
      refunding:"Refunding",
      refunded:"Refunded",
      refundfail:"Refund Fail",
    },
    ph:{
      negative_as_cost:"Negative Number As Cost",
      user_id:"Return bill to member account, skip if not filled in",
      reason:"Please Input Reason"
    }
  },
  device:{
    day_order_num:"Daily Order Amount",
    week_order_num:"Week Order Amount",
    month_order_num:"Month Order Amount",
    charge_rule:"Charge Rule",
    add:"Add",
    delete:"Delete",
    power_gt:"When power is greater than",
    great_than:"Great Than",
    the_price:"The Price Is",
    price_unit:"$/hour",
    latitude:"latitude",
    longitude:"longitude",
    big_power_money:"price of super power",
    middle_power_money:"price of high power",
    small_power_money:"price of nomal power",
    big_power_range:"range of super power",
    middle_power_range:"range of high power",
    small_power_range:"range of nomal power",
    electric_money:"price/100watt",
    please_choose_status:"Please Choose Status",
    please_input_full_info:"Please Input Full Info",
    sure_open_slot:"Are You Sure To Open Slot?",
    sure_restart_dev:"Are you sure you want to restart the device?",
    cmd_sent:"Command sent, please wait",
    alert:"Alert",
    site_name:"Site Name",
    life_time:"LifeTime(Second)",
    update_time:"Last Updated At",
    temp:"Temperature",
    mac:"Mac",
    meas_power:"Power",
    all_power:"Total Power",
    efficiency:"Efficiency",
    electric_quantity:"Electricity",
    electric_price:"Electricity Price",
    coordinate:"Coordinate",
    img_dev:"Image Device",
    img_site:"Image Site",
    img_env:"Image Env.",
    slot_num_all:"Slot Amount",
    slot_num_empty:"Empty Slot Amount",
    slot_num_bat:"Battery Slot Amount",
    slot_num_error:"Abnormal Slot Amount",
    slot_num_box:"Charge Slot Amout",
    slot_num_pile:"Pile Amount",
    pile_num_all:"Pile Amount",
    pile_num_empty:"Empty Pile Amount",
    pile_num_error:"Abnormal Pile Amount",
    open_all_slots:"Open All Slots",
    reset:"Restart",
    close_heater:"Turn off heater",
    open_heater:"Turn on heater",
    slot_battery:"Battery Slot",
    slot_empty:"Empty Slot",
    slot_type:"Type",
    slot_warning:"Warning",
    slot_status:"Slot Status",
    device_id:'BatteryID',
    update_device_last:'Last Update BatteryID',
    specs:"Equipment Specifications",
    voltage:"Voltage",
    current:"Current",
    slot_no:"Slot NO.",
    pile_no:"Pile NO.",
    pile_type:"Type",
    pile_type_battery:"Battery Pile",
    pile_type_empty:"Empty Pile",
    sp_battery:"Battery",
    sp_empty:"Empty",
    sp_error:"Abnormal",
    open_slot:"Open Slot",
    createWorkOrder:"Create Work Order",
    enable:"Enable",
    slotRestart:"Restart",
    sure_restart_slot:"Are you sure you want to restart the slot?",
    disable:"Disable",
    address:"Device Address",
    slot_loading:"Loading...",
    pile_loading:"Loading...",
    name:"Device Name",
    type:"Device Type",
    pattern:"Device model",
    dev_id:"Device NO.",
    holder:"Holder",
    soc:"SOC",
    battery_soc:"Battery SOC",
    error_no:"Error NO.",
    charge_count:"Charge Count",
    status:"Status",
    operate_status:"Operation Status",
    online_status:"Online Status",
    deposit:"Deposit",
    deposit2:"Deposit",
    agent:"Agent",
    prices:{
      charge:"Charge Price",
      deposit:"Deposit",
      fine:"Find For Not Closing Slot",
    },
    moneys:{
      charge:"Charge Price",
      save:"Deposit",
      err_close:"Wrong Money Format",
    },
    isrent:'Is it available for rent',
    isreplaceable:'Is it return',
    free_deposit_time2:"Free storage time",
    free_deposit_time:"Free storage time",
    statuses:{
      all:"All",
      charging:"Charging",
      full:"Full",
      normal:"Normal",
      enabled:"Enabled",
      disabled:"Disabled",
      online:"Online",
      offline:"Offline",
      enrent:'Available for rent',
      unrent:'Not available for rent',
      replaceable:'Return',
      unreplaceable:'Not return',
    },
    types:{
      all:"All",
      ebox:"Ebox",
      cbox:"Cbox",
      pile:"Pile",
      cpbox:"MixBox",
    },
    no:"NO.",
    holder:"Holder",
    power:"Power",
    slot_id:'Slot Id',
    soh:"SOH",
    volt:"Volt",
    batteryTrajectory:"Battery Trajectory",
    cabinetUpdateTime:"Cabinet Update Time",
    batteryUpdateTime:"Battery Update Time",
    cell_count:"Cell Count",
    protect:"Protect",
    input_current:"Input Current",
    charge_status:"Charge Status",
    input_volt:"Input Volt",
    inner_input_current:"Inner Input Current",
    env_temp:"Env. Temperature",
    cell_temp:"Cell Temperature",
    board_temp:"Board Temperature",
    move_status:{
      name:'Move status',
      move:'Move',
      still:'Still',
      storage:'Storage',
      hibernate:'Hibernate',
    },
    control_status:{
      name:'Control Status',
      discharge:'Discharge status',
      charge:'Charging status',
      load:'Load in place',
      charged:'Charging status',
      empty:'No-load state',
    },
    net_status:{
      name:'Network status',
      offline:'Offline',
      online:'Online',
    },
    use_status:{
      name:'Use Status',
      idle:'idle',
      cabinet:'In the cabinet',
      rider:'In the rider',
      uncabinet:'Not on board',
    },
    rate:'speed(km/h)',
    deadline:'Deadline',
    ebox:{
      minPower:'Minimum replaceable power',
      keepEmptyNum:'Keep short positions',
      keepBatteryNum:'Number of battery compartments reserved',
      startChargingTime:'Start charging time',
      endChargingTime:'End charging time',
      chargingTime:'Charging time',
      startChargingSOC:'Start charging SOC',
      faultCodes:'Fault Codes',
      faultChineseName:'Fault Chinese name',
      enabledState:'Enabled state',
      switchStatus:'Switch Status',
      replaceableBattery:'Replaceable battery',
      disableBin:'Disable Bin',
    },
    carPile:{
      type:'Pile Type',
      maxPower:'Maximum power',
      realTimePower2:'Real-time power',
      totalGunNum:'Total gun number',
      gunStatus:'Gun status',
      gunNo:'Gun No.',
      realtimePower:'Real-time power',
      operatePara:'Operate parameter',
      billingMethod:'Billing method',
      siteId:'Site ID',
      directCurrent:'Direct current',
      altCurrent:'Alternating current',
      status:{
        idle:'Idle',
        notCharging:'Not charging',
        charging:'Charging',
        noUploadGun:'No upload gun',
        appointProgress:'Appoint progress',
        fault:'Fault',
      },
      method:{
        time:'Time billing',
        grade:'Billed by power level plus duration',
        power:'Electricity billing',
      }
    },
  },
  // 实体卡
  card:{
    number:"Number",
    deposit:'Deposit',
    bind_user:"Bind User",
    unbind_user:"Unbind user",    
    bind_package:"Bind Package",
    unbind:"Unbind",    
    renewal:"Renewal",
    accountType:"Account Type",    
    buyCombo:"Buy Combo",    
    cashRece:"Total cash received",    
    WeChat:"WeChat Mini Program",
    bindWeChat:"Bind WeChat Account",    
    Automatic:"Automatic",
    beforeExp:"Current Package expires",    
    afterExp:"Package expires after renewal",    
    expire:"expire",
    month:'Month',
    times:'Times',
    currency:'RMB',
    please_input_account:'User Account',
    binded_user:'Used',
    bind_tips:'Are you sure to bind to the [xx/xx] package for this user?',
    bind_status_no:'not bind'
  },
  openSlotsLit:{
      user_id:'User ID',
      dev_id:'Device ID',
      open_slots_type:'Opening Type',
      order_no:'Order No',
      open_slots_info:'Opening Info',
      open_slots_result:'Opening Results',
      agent_code:'Agent Code',
      site_name:'Site Name',
      slot_no:'Slot No',
      ins_battery_soc:'Insert Battery Soc',
      ins_battery_id:'Insert Battery Id',
      pull_out_battery_soc:'Pull out battery Soc',
      pull_out_battery_id:'Pull out battery Id',
      create_time:'Created Time',
      result:{
        all:'All',
        success:'Success',
        fail:'Fail'
      },
      types:{
        all:'All',
        all_slot:'All Slot',
        all_empty_slot:'All Empty Slot',
        all_battery_slot:'All Battery Slot',
        Specify_slot:'Specify Slot',
        full_charge_slot:'All fully charged',
      },
      no_data:"no data",
      export_details:'Export details',
      order_export:'Export order',
      loading:'Loading, please wait',
      opening_information_table:'OpeningInformationTable'
  },
  alertRecord:{
      OrderNum:'Order No',
      agent_code:'Agent Code',
      site_name:'Site Name',
      mac:'Device Mac',
      dev_id:'Device ID',
      phone_num_type:'Calling Number Type',
      phone_num_type_one:'Public',
      phone_num_type_two:'Special',
      dial_phone:'Calling Number',
      phones:'called number',
      type:'Fault Type',
      call_id:'Call Id',
      status_code:'Status Code',
      res:'Result',
      create_time:'Created Time',
      result:{
        all:'All',
        success:'Success',
        fail:'Fail'
      },
      alarm_information_table:'AlarmInformationTable'
  },
  activityManage:{
    title:'Activity name',
    time:'Activity time',
    start_time:'Starting time',
    end_time:'End Time',
    type:'Type of activity',
    activity_status:'Active status',
    push_type:'Push method',
    style:'Activity style',
    award_type:'Reward type',
    award_num:'Number of rewards',
    award_unit:'Award units',
    award_rule:'Activity Rules',
  },
  receive_list:{
    order_code:'Order code',
    coupon_code:'Coupon Code',
    receive_id:'ID',
    receive_info:'Get coupon information',
    Distributer:'Distributer',
    receive_id:'UserId',
    user_info:'Get user information',
    receive_time:'Collection time',
    status:'Collection status',
    use_channel:'Use channels',
    coupon_type:'Coupon type',
    use_time:'Usage time',
    remark:'Remark',
    coupon:'Coupon',
    face_value:'Denomination',
    face_num:'Denomination/The remaining amount',
    num:'Amount',
    remain_num:'The remaining amount',
    user_type:'User type',
    user:'User',
    channel:'Channel',
    withdraw:'withdraw',
    statuses:{
      unreceive:'Unaccalimed',
      unuse:'Unused',
      used:'Used',
      expired:'Expired',
      withdraw:'Withdrawn',
    },
    query:{
      current_status:'Current status'
    },
    receive_phone:'Recipient phone number',
    receive_channel:'Receipt channels',
    phone_placeholder:`Please enter the recipient’s mobile phone number. Please use half-width commas to separate multiple mobile phone numbers. For example:
13700000000,13800000000
Or use blank lines to split such as:
13700000000
13800000000
    `
  },
  coupon:{
    name:'Coupon name',
    type:'Type',
    face_value:'Denomination',
    coupon_issuance_method:'Coupon issuance',
    circulation:'Circulation',
    delayedActivation:'Delayed Enable',
    actual_circulation:'Actual circulation',
    start_time:'Starting time',
    end_time:'Deadline',
    time:'Start and end time',
    remark:'Remark',
    status:'Status',
    rule:'rules',
    render:{
      type:{
        cash:'Cash coupon',
        time:'Lease extension coupon'
      },
      method:{
        new_user_register:'New User Registration',
        develop_new_user:'Develop new users',
        target:'Targeted issuance of coupons',
        combo_aotu:'Automatically issue coupons for packages',
        h5:'H5 link automatically received',
      }
    }
  },
  restriction_rule:{
    name:'Restricted name',
    description:"Limitation description",
    channel_restrict:"Channel restrictions",
    product_restrict:"Product restrictions",
    combo_restrict:"Package restrictions",

  },
  temporary_record:{
    status:'status',
    statuses:{
      failed:'Temporary storage failed',
      staging:'Temporary storage success',
      cancel_fail:'Destage failed',
      cancel_success:'Destage success'
    },
    start_time:'Temporary storage start time',
    release_time:'Temporary release time',
    days:'Days of temporary storage',
    remark:'Remark',
    extend_lease:'Temporary storage to extend the lease',
    cabinet_sn:'Temporary storage cabinet SN number',
    Cangmen_no:'Temporary storage cabinet door number',
    battery_sn:'Temporary storage of battery SN',
    battery_soc:'Temporary battery soc',
    remove_cabinet_sn:'Release the SN number of the temporary storage cabinet',
    remove_Cangmen_no:'Unlock the door number of the temporary storage cabinet',
    remove_battery_sn:'Release the temporary storage of battery SN',
    remove_battery_soc:'Release temporary battery soc',
    order:'Order number',
    change_time:'Change the time',
    expire_date:'Package expiration time',
    remove_expire_date:'Expiration time after cancellation',
  },
  login:{
    unlogin:"Unlogin",
    confirm_logout:"Are You Sure To Logout?",
    wechat_scan_to_login:"Wechat Scan",
    btn_login:"Log In",
    verification_code:"Verification Code",
  },
  bigScreen:{
    title:'MDA management platform',
    title2:'management platform',
    all:'All',
    emotor:'Charging cabinet',
    ebox:'Swapping cabinet',
    cbox:'Vehicle',
    online :'Online',
    offline:'Offline',
    InDev:'InDev',
    InMan:'InMan',
    InIdle:'InIdle',
    total_revenue:'Total revenue',
    daily_earnings:'Daily earnings',
    amount_received:'Amount received',
    today_income:"Today's income",
    refund_amount :'Refund amount',
    today_refund:"Today's refund",
    user_data:"User's data",
    registered_users:'Registered users',
    valid_users:'Valid users',
    added_users:'Added users',
    operation_data:'Operation',
    BAT_swapping:'Total BAT swapping',
    total_BAT_number:'Total BAT number',
    total_vehicle:'Total vehicle',
    success_rate_swapping:'Success rate of swapping',
    battery_rentals:'Number of rented batteries',
    vehicle_rentals:'Number of rented vehicle',
    unit:'Unit',
    not_active:'Not active',
    PCS:'pcs',
    digit:'pcs',
    total_battary:'Total number of batteries',
    total_emotor:'Total number of Vehicle',
    total_ebox:'Total number of swapping cabinet',
    exBatId:'Battery replacement order ID'
  },
  feedback:{
    time:"Feedback At",
    process:"Process",
    user:"User",
    content:"Content",
    reply:"Replay",
    error:"Error",
    processing_time :"processing time",
    statuses:{
      all:"All",
      undeal:"Undeal",
      done:"Done",
      doing:"Doing",
    },
    ph:{
      choose:"Please Choose",
      user:"Please Input Feedback User",
      content:"Please Input Feedback Content",
    }
  },
  agentsSwitch:{
    SMS_verification:'SMS verification',
    authentication:'Authentication',
    clearance_package:'End of lease removal package',
    coupon_function:'Coupon function',
    temporary_storage:'Temporary storage function',
    new_feature:'New user function',
    package_bundled:'Lease and swap package bundle',
    voice_broadcasting:'Voice broadcast function',
    late_payment_deduction:'Overdue deduction function',
    first_package:'Only first-time package available',
    Enable:'Enable',
    Disable:'Disable',
    save_settings:'Save settings'
  },
  statistics:{
    revenue_statistics:'Revenue Statistics',
    total_revenue:'Total revenue',
    yesterday_earnings:"Yesterday's earnings",
    yesterday_revenue_orders:"Yesterday's revenue orders",
    today_earnings:"Today's earnings",
    today_revenue_orders:"Today's revenue orders",
    yesterday_battery_replacement:"Yesterday's battery orders",
    today_battery_swapping:"Today's battery orders",
    income:"income",
    number_of_orders:"Number of orders",
    cabinet_statistics:"Cabinet Statistics",
    on_line:"Online",
    offline:"Offline",
    cabinets_with_abnormal_compartments:"Abnormal storage cabinets",
    Cabinets_with_prohibited_storage:"Disabled compartment cabinets",
    non_battery_cabinets:"Non-replaceable battery cabinets",
    empty_containers:"No empty containers",
    total:"Total",
    average_revenue_of_cabinets:"Avg. revenue per cabinet",
    battery_statistics:"Battery Statistics",
    idle:"Idle",
    number_of_cabinets:"Number of cabinets",
    in_the_rider:"In the rider",
    offline_battery:"Offline battery",
    online_battery:"Online Battery",
    user_statistics:"User Statistics",
    renting_valid_package:"On rent",
    be_overdue:"Overdue",
    terminate_the_lease:"Lease cancellation",
    new_lease:"New Lease",
    renewal_of_lease:"Renewal",
    number_of_battery_swapping_users:"Battery swap users",
    order_statistics:"Order Statistics",
    cabinet_order_statistics:"Cabinet Order Statistics",
    battery_replacement_orders:"Battery replacement order statistics",
    number_of_battery_replacements:"Number of battery replacements",
    site_name:"Site Name",
    device_ID:"Device ID",
    user_id:"User ID",
    user_nickname:"User Nickname",
    phone_number:"Phone number",
    battery_replacements_num:"Number of battery replacements",
    failed_order_details:"Failed order details",
    nickname:"Nick name",
    number:"Number",
    equipment_number:"Equipment No.",
    failure_reason:"Cause of failure",
  },
  announcementList:{
    title:'title',
    pop_window_position:'Home pop-up window location',
    start_time:'Start time',
    end_time:'End Time',
    above_screen:'Top of screen',
    middle_screen:'Center of screen',
    not_display_pop:'Do not show pop-ups',
    announcement_title:'Announcement Title',
    announcement_time:'Announcement time',
    do_you_jump:'Whether to jump',
    number_of_pop:'Pop-up times',
    allow_no_reminders:'Allow absence reminder',
    announcement_content:'Announcement content',
    please_enter_content:'Please enter the announcement content',
    jump_new_customer:'Jump to new users',
    jump_to_coupon:'Skip to coupons'
  },
  overdue:{
    processing_status:'Processing Status',
    all:'all',
    already_overdue:'Expired',
    overdue_lease:'Overdue lease renewal',
    termination_lease:'Overdue lease',
    manual_end:'Manual end',
    completion_status:'Completion status',
    Unfinished:'Unfinished',
    Completed:'Completed',
    user_nickname:'User Nickname',
    user_mobile:'User mobile phone number',
    overdue_fees:'System overdue fee',
    settlement_fees:'Settlement overdue fees',
    system_overdue_days:'System overdue days',
    settlement_overdue_days:'Settlement overdue days',
    maximum_deduction:'Maximum deduction amount',
    standard_amount:'Amount standard for exemption from deduction',
    actual_total_consumption:'Actual total consumption amount',
    package_expiration:'Package expiration date',
    without_deduction:'Free days',
    payment_deduction:'Overdue deduction price',
    renewal_deduction:'Renewal deduction %',
    rent_deduction:'Lease termination deduction %',
    purchase_frequency:'Number of purchases',
    operate:'operate',
    audit_settlement:'Audit settlement',
  },
  workOrder:{
    work_status:'Work status',
    state_emergency:'state',
    service_number:'Customer Service Number',
    sales_number:'After sales number',
    batch_confirm:'Batch confirmation',
    cabinet_ID:'Cabinet ID',
    title:'title',
    content:'content',
    order_number:'Work order number',
    customer_service:'customer service',
    after_sales:'After Sales',
    service_description:'Customer Service Description',
    after_supplement:'After sales supplement',
    door_ID:'Warehouse door ID',
    battery_ID:'Battery ID',
    site_name:'site name',
    cabinet_address:'Cabinet address',
    picture:'picture',
    view:'view',
    update_ID:'Update user ID',
    update_time:'update time',
    edit_workorder:'Edit Work Order',
    cabinet_compartment:'Cabinet compartment number',
    workorder_title:'Work Order Title',
    descriptive_content:'descriptive content',
    priority:'priority',
    change_state:'Change state',
    pending_orders:'Pending orders',
    received_order:'Received order',
    completed:'Completed',
    confirmed:'Confirmed',
    incomplete:'incomplete',
    pending:'Pending',
    cancel:'cancel',
    work_orderimage:'Work order image',
    general:'general',
    emergency:'emergency',
    very_urgent:'Very urgent',
  },
  beOverdue:{
    overdue_setting:'Overdue charge setting',
    example_calculation:'Example of Deduction Calculation',
    overdue_txt1:'Remaining days after renewing the package purchase: Current package purchase days - (overdue days - deductible days) * Renewal discount',
    overdue_txt2:'Example (assuming a 10 day overdue and a 30 day package)',
    overdue_txt3:'Remaining deposit after surrendering the lease: deposit - (overdue days - days without deduction) * daily overdue penalty * surrender discount',
    overdue_txt4:'Example (assuming a deposit of 300 yuan, overdue for 10 days)',
    free_days:'Free days',
    overdue_penalty:'Overdue penalty exemption amount standard',
    daily_fine:'Daily overdue fine',
    renewal_discount:'Renewal discount',
    return_discount:'Return of lease discount',
    overdue_limit:'Overdue deduction limit',
    overdue_txt5:'Important tips: ([time] in the text represents the remaining days of overdue payment, [money] represents the overdue fee, and [day] represents the number of days deducted for package renewal. This is a dynamic value calculated for the user. Please do not modify it. Just modify the text or change the position of [time]. Please note that the copy you write can match your own overdue fee settings. The example on the left below is the example on the right and the reference effect after conversion)',
    overdue_txt6:'Pop-up reminder text before package expires',
    overdue_txt7:'Reference effect of the copy after conversion',
    overdue_txt8:'Overdue reminder copy',
  },
  billingPolicy:{
    policy_name:'Policy Name',
    billing_rules:'Billing rules',
    rule_name:'Rule Name',
    enter_rulename:'Please enter a rule name',
    fee_settings:'Fee Settings',
    settings_txt1:'(Prices during peak, flat and valley periods are mandatory. Since electricity prices vary from place to place, please fill in the information based on the actual local electricity prices.)',
    peak_period:'Peak period',
    peak_hours:'Peak hours',
    normal_period:'Normal period',
    valley_period:'Valley period',
    settings_txt2:'Electricity fee (yuan/kWh)',
    settings_txt3:'Service fee (yuan/degree)',
    charging_range:'Charging price range',
    settings_txt4:'(The minimum interval for time setting is 30 minutes; up to 48 billing periods can be set.)',
    bucket_type:'bucket type',
    start_time:'Start Time',
    end_time:'End Time',
    settings_txt5:'(yuan/kWh)',
    electricity:'Electricity',
    service_charge:'service charge',
  },
  siteManager:{
    detailed_address:'Detailed address',
    opening_hours:'Opening hours',
    site_information:'Site Information',
    site_txt1:'Recommended format: All day or Monday to Sunday 00:00-24:00',
    site_longitude:'Site longitude',
    site_latitude:'Site Latitude',
    parking_type:'Parking fee type',
    parking_information:'Parking fee information',
    siteManager_txt1:'Recommended format: XX yuan/degree or free parking, etc',
    operational_status:'Operational status',
    stop_business:'stop doing business',
    at_camp:'At Camp',
    charging_station:'Type of charging station',
    site_images:'Site images',
    charging_price:'Charging price',
    time_slot:'time slot',
    parking_fee:'Parking fee',
    free_parking:'Free parking',
    free_time:'Free for a limited time',
    charging_free:'Charging Free',
    public_stations:'Public Charging Stations',
    commercial_stations:'Commercial Charging Stations',
    residential_station:'Residential area charging station',
    highway_station:'Highway charging station',
    smart_station:'Smart Charging Station',
  },
  withdrawFunds:{
    revenue_status:'revenue_status',
    revenue_model:'Revenue model',
    mobile_phone:'Beneficiary mobile phone number',
    mobile_phone2:'Invited person  mobile phone',
    system_status:'System transfer status',
    batch_withdrawal:'Batch withdrawal',
    Inviter:'Inviter',
    Invitee:'Invitee',
    user_ID:'Beneficiary User ID',
    name_beneficiary:'Name of beneficiary',
    invited_ID:'Invited person ID',
    name_invitee:'Name of invitee',
    amount_invitee:'The amount of income earned by the invitee',
    new_order:'New order number',
    withdrawal_method:'Withdrawal method',
    evidence_income:'Evidence of income',
    operator_ID:'Operator ID',
    notes:'notes',
    withdrawal_date:'Withdrawal date',
    system_time:'System transfer time',
    cash_value:'Cash Value',
    coupons:'Coupons',
    transfer_pictures:'Transfer pictures',
    system_transfer:'System transfer failure reminder',
  }
}
