import i18n from '@/data/i18n/index'
const t = i18n.global.t
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Stat from '../views/Stat.vue'
import Login from '../views/Login.vue'
import DecisionMarket from '../views/decision/market.vue'
import DecisionHot from '../views/decision/hot.vue'
import DecisionStatistics from '../views/decision/statistics.vue'  
import OperationPoints from '../views/operation/points.vue'
import OperationBatterys from '../views/operation/batterys.vue'
import OperationBattery from '../views/operation/OperationBattery.vue'
import OperationScatter from '../views/operation/scatter.vue'
import OperationUsers from '../views/operation/users.vue'
import OperationProperties from '../views/operation/properties.vue'
import OperationOrders from '../views/operation/orders.vue'
import OperationBikes from '../views/operation/bikes.vue'
import FinanceReport from '../views/finance/report.vue'
import LogSystem from '../views/log/system.vue'
import LogApi from '../views/log/api.vue'
import msgLog from '../views/log/msg.vue'
import electricityStat from '../views/log/electricityStat.vue'
import AfterSellFeedBack from '../views/after_sell/feedback.vue'
import MemberProfile from '../views/member/profile.vue'
import MemberBikes from '../views/member/bikes.vue'
import MemberCards from '../views/member/cards.vue'
import MemberList from '../views/member/list.vue'
import MemberAgents from '../views/member/agents.vue'
import SecondTierAgent from '../views/member/SecondTierAgent.vue'
import ThirdTierAgent from '../views/member/ThirdTierAgent.vue'
import FourthTierAgent from '../views/member/FourthTierAgent.vue'
import agentsSwitch from '../views/member/agentsSwitch.vue'
import ExpensesMonthCard from '../views/expenses/monthcard.vue'
import ExpensesCombo from '../views/expenses/combo.vue'
import siteManager from '../views/expenses/siteManager.vue'
import h5Manage from '../views/expenses/h5Manage.vue'
import MeiTuan from '../views/expenses/meiTuan.vue'
import billingPolicy from '../views/expenses/billingPolicy.vue'
import ExpensesCarCombo from '../views/expenses/CarCombo.vue'
import ExpensesBeOverdue from '../views/expenses/beOverdue.vue'
import OrderBattery from '../views/order/battery.vue'
import OrderBike from '../views/order/bike.vue'
import OrderCabinet from '../views/order/cabinet.vue'
import OrderPile from '../views/order/pile.vue'
import OrderCarPile from '../views/order/carPile.vue'
import OrderCbox from '../views/order/cbox.vue'
import OrderCombo from '../views/order/combo.vue'
import OrderOffline from '../views/order/offline.vue'
import OrderRecharge from '../views/order/recharge.vue'
import OrderBatteryReturn from '../views/order/batteryReturn.vue'
import temporaryRecord from '../views/order/temporaryRecord.vue'
import importRecord from '../views/order/importRecord.vue'
import EboxList from '../views/ebox/list.vue'
import openSlotsList from '../views/openSlots/list.vue'
import openSlots from '../views/openSlots/simple.vue'
import alertRecord from '../views/alertRecord/list.vue'
import activityManage from '../views/activityManage/list.vue'
import discountCoupon from '../views/activityManage/coupon.vue'
import CarCoupon from '../views/activityManage/CarCoupon.vue'
import restrictionRule from '../views/activityManage/rule.vue'
import recruitNew from '../views/activityManage/recruitNew.vue'
import announcementList from '../views/activityManage/announcementList.vue'
import withdrawFunds from '../views/activityManage/withdrawFunds.vue'
import award from '../views/activityManage/award.vue'
import CboxList from '../views/cbox/list.vue'
import PileList from '../views/pile/list.vue'
import CarPileS from '../views/pile/carPile.vue'
import CPboxList from '../views/cpbox/list.vue'
import BatteryList from '../views/battery/list.vue'
import BillRefund from '../views/bill/refund.vue'
import BillRefundRecord from '../views/bill/refund_record.vue'
import BillRecord from '../views/bill/record.vue'
import BillBad from '../views/bill/bad_bill.vue'
import BillSettle from '../views/bill/settle.vue'
import overdue from '../views/bill/overdue.vue'
import RightsManager from '../views/rights/manager.vue'
import RightsRole from '../views/rights/role.vue'
import Rights from '../views/rights/rights.vue'
import workOrderSet from '../views/workOrder/workOrderSet.vue'
import workOrderList from '../views/workOrder/workOrderList.vue'
import CardList from '../views/card/list.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: t('menu.home') , keepAlive:true},
  },
  {
    path: '/stat',
    name: 'Stat',
    component: Stat
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/workOrder',
  //   name: 'workOrder',
  //   component: workOrder,
  //   meta: { title: '工单管理', keepAlive:true },
  // },
  {
    path: '/workOrder/workOrderSet',
    name: 'workOrderSet',
    component: workOrderSet,
    meta: { title:'工单设置' },
  },
  {
    path: '/workOrder/workOrderList',
    name: 'workOrderList',
    component: workOrderList,
    meta: { title:t('menu.workOrderList') },
  },
  {
    path: '/rights',
    name: 'Rights',
    component: Rights,
    meta: { title: t('menu.rights_manage'), keepAlive:true },
  },
  {
    path: '/rights/manager',
    name: 'RightsManager',
    component: RightsManager,
    meta: { title: t('menu.managers') },
  },
 
  {
    path: '/rights/role',
    name: 'RightsRole',
    component: RightsRole,
    meta: { title: t('menu.role_manage') },
  },
  {
    path: '/bill/refund',
    name: 'BillRefund',
    component: BillRefund,
    meta: { title: t('menu.refund'), keepAlive:true },
  },
  {
    path: '/bill/refund/record',
    name: 'BillRefundRecord',
    component: BillRefundRecord,
    meta: { title: t('menu.refund_record') ,keepAlive:true},
  },
  {
    path: '/bill/bad',
    name: 'BillBad',
    component: BillBad,
    meta: { title: t('menu.badbill') , keepAlive:true},
  },
  {
    path: '/bill/record',
    name: 'BillRecord',
    component: BillRecord,
    meta: { title: t('menu.turnover_record'), keepAlive:true },
  },
  {
    path: '/bill/settle',
    name: 'BillSettle',
    component: BillSettle,
    meta: { title: t('menu.settle') , keepAlive:true},
  },
  {
    path: '/bill/overdue',
    name: 'overdue',
    component: overdue,
    meta: { title: t('menu.overdue') , keepAlive:true},
  },
  {
    path: '/ebox', 
    name: 'EboxList',
    component: EboxList,
    meta: { title: t('menu.ebox_list'), keepAlive:true },
  },
  {
    path: '/openSlots',
    name: 'openSolotRecord',
    component: openSlotsList,
    meta: { title: t('menu.open_solot_record'), keepAlive:true },
  },
  {
    path: '/openSlots/simple',
    name: 'openSolot',
    component: openSlots,
    meta: { title: t('menu.open_solot'), keepAlive:true },
  },
  {
    path: '/alertRecord',
    name: 'alertRecord',
    component: alertRecord,
    meta: { title: t('menu.alert_record'), keepAlive:true },
  },
  {
    path: '/activityManage',
    name: 'activityManage',
    component: activityManage,
    meta: { title: t('menu.recruit_manage') },
  },
  {
    path: '/activityManage/rule',
    name: 'restrictionRule',
    component: restrictionRule,
    meta: { title: t('menu.restriction_rule') },
  },
  {
    path: '/activityManage/coupon',
    name: 'discountCoupon',
    component: discountCoupon,
    meta: { title: t('menu.discount_coupon') },
  },
  {
    path: '/activityManage/CarCoupon',
    name: 'CarCoupon',
    component: CarCoupon,
    meta: { title: '骑行优惠券' },
  },
  {
    path: '/activityManage/award',
    name: 'awardManage',
    component: award,
    meta: { title: t('menu.award_manage'), keepAlive:true },
  },
  {
    path: '/activityManage/recruitNew',
    name: 'recruitNew',
    component: recruitNew,
    meta: { title: t('menu.recruit_new'), keepAlive:true },
  },
  {
    path: '/activityManage/announcementList',
    name: 'announcementList',
    component: announcementList,
    meta: { title: t('menu.announcement_list') },
  },
  {
    path: '/activityManage/withdrawFunds',
    name: 'withdrawFunds',
    component: withdrawFunds,
    meta: { title: t('menu.withdraw'), keepAlive:true },
  },
  {
    path: '/cbox',
    name: 'CboxList',
    component: CboxList,
    meta: { title: t('menu.cbox_list'), keepAlive:true },
  },
  {
    path: '/cpbox',
    name: 'CPboxList',
    component: CPboxList,
    meta: { title: t('menu.cpbox_list'), keepAlive:true },
  },
  {
    path: '/pile',
    name: 'PileList',
    component: PileList,
    meta: { title: t('menu.pile_list'), keepAlive:true },
  },
  {
    path: '/pile/carPile',
    name: 'CarPileS',
    component: CarPileS,
    meta: { title: t('menu.car_pile_list'), keepAlive:true },
  },
  {
    path: '/battery',
    name: 'BatteryList',
    component: BatteryList,
    meta: { title: t('menu.battery_list'), keepAlive:true },
  },
  {
    path: '/order/battery',
    name: 'OrderBattery',
    component: OrderBattery,
    meta: { title: t('menu.orders.rent') , keepAlive:true},
  },
  ,
  {
    path: '/log/electricityStat',
    name: 'electricityStat',
    component: electricityStat,
    meta: { title: t('menu.electricity_stat'), keepAlive:true },
  },
  {
    path: '/order/battery/return',
    name: 'OrderBatteryReturn',
    component: OrderBatteryReturn,
    meta: { title: t('menu.orders.return'), keepAlive:true },
  },
  {
    path: '/order/temporaryRecord',
    name: 'temporaryRecord',
    component: temporaryRecord,
    meta: { title: t('menu.orders.temporary_record'), keepAlive:true},
  },
  {
    path: '/order/importRecord',
    name: 'importRecord',
    component: importRecord,
    meta: { title: t('menu.orders.import_record'), keepAlive:true },
  },
  {
    path: '/order/combo',
    name: 'OrderCombo',
    component: OrderCombo,
    meta: { title: t('menu.orders.setmenu'), keepAlive:true },
  },
  {
    path: '/order/recharge',
    name: 'OrderRecharge',
    component: OrderRecharge,
    meta: { title: t('menu.orders.recharge'), keepAlive:true },
  },
  {
    path: '/order/bike',
    name: 'OrderBike',
    component: OrderBike
  },
  {
    path: '/order/cabinet',
    name: 'OrderCabinet',
    component: OrderCabinet,
    meta: { title: t('menu.orders.exchange') , keepAlive:true}
  },
  {
    path: '/order/pile',
    name: 'OrderPile',
    component: OrderPile,
    meta: { title: t('menu.orders.pile') , keepAlive:true}
  },
  {
    path: '/order/carPile',
    name: 'OrderCarPile',
    component: OrderCarPile,
    meta: { title: t('menu.orders.carPile'), keepAlive:true}
  },
  {
    path: '/order/cbox',
    name: 'OrderCbox',
    component: OrderCbox,
    meta: { title: t('menu.orders.cbox'), keepAlive:true }
  },
  {
    path: '/order/offline',
    name: 'OrderOffline',
    component: OrderOffline,
    meta: { title: t('menu.orders.offline'), keepAlive:true }
  },
  {
    path: '/expenses/monthcard',
    name: 'ExpensesMonthCard',
    component: ExpensesMonthCard,
  },
  {
    path: '/expenses/combo',
    name: 'ExpensesCombo',
    component: ExpensesCombo,
    meta: { title: t('menu.set_menu_manage'), keepAlive:true }
  },
  {
    path: '/expenses/siteManager',
    name: 'siteManager',
    component: siteManager,
    meta: { title: t('menu.siteMange'), keepAlive:true },
  },
  {
    path: '/expenses/h5Manage',
    name: 'h5Manage',
    component: h5Manage,
    meta: { title: t('menu.h5Mange'), keepAlive:true },
  },
  {
    path: '/expenses/meiTuan',
    name: 'MeiTuan',
    component: MeiTuan,
    meta: { title: t('menu.meituan'), keepAlive:true },
  },
  {
    path: '/expenses/billingPolicy',
    name: 'billingPolicy',
    component: billingPolicy,
    meta: { title: t('menu.billStrategy'), keepAlive:true }
  },
  {
    path: '/expenses/CarCombo',
    name: 'ExpensesCarCombo',
    component: ExpensesCarCombo,
    meta: { title: '租车换电套餐' }
  },
  {
    path: '/expenses/beOverdue',
    name: 'ExpensesBeOverdue',
    component: ExpensesBeOverdue,
    meta: { title: t('menu.ExpensesBeOverdue') }
  },
  {
    path: '/member/profile',
    name: 'MemberProfile',
    component: MemberProfile,
    meta: { title: t('menu.profile') }
  },
  {
    path: '/member/bikes',
    name: 'MemberBikes',
    component: MemberBikes,
  },
  {
    path: '/member/cards',
    name: 'MemberCards',
    component: MemberCards,
  },
  {
    path: '/member/list',
    name: 'MemberList',
    component: MemberList,
    meta: { title: t('menu.member_list'), keepAlive:true }
  },
  {
    path: '/member/agents',
    name: 'MemberAgents',
    component: MemberAgents,
    meta: { title: t('menu.agent_list'), keepAlive:true  }
  },
  {
    path: '/member/SecondTierAgent',
    name: 'SecondTierAgent',
    component: SecondTierAgent,
    meta: { title: t('menu.SecondTierAgent'), keepAlive:true  }
  },
  {
    path: '/member/ThirdTierAgent',
    name: 'ThirdTierAgent',
    component: ThirdTierAgent,
    meta: { title: t('menu.ThirdTierAgent'), keepAlive:true  }
  },
  {
    path: '/member/FourthTierAgent',
    name: 'FourthTierAgent',
    component: FourthTierAgent,
    meta: { title: t('menu.FourthTierAgent'), keepAlive:true  }
  },
  {
    path: '/member/agentsSwitch',
    name: 'agentsSwitch',
    component: agentsSwitch,
    meta: { title: t('menu.agents_switch') }    
  },
  {
    path: '/after_sell/feedback',
    name: 'AfterSellFeedBack',
    component: AfterSellFeedBack,
    meta: { title: t('menu.feedback') } 
  },
  {
    path: '/decision/market',
    name: 'DecisionMarket',
    component: DecisionMarket,
    meta: { title: t('menu.decision.market') } 
  },
  {
    path: '/decision/host',
    name: 'DecisionHot',
    component: DecisionHot,
    meta: { title: t('menu.decision.hot') } 
  },
  {
    path: '/decision/statistics',
    name: 'DecisionStatistics',
    component: DecisionStatistics,
    meta: { title: t('menu.decision.statistics'), keepAlive:true } 
  },
  {
    path: '/operation/points',
    name: 'OperationPoints',
    component: OperationPoints,
    meta: { title: t('menu.distribution_site') } 
  },
  {
    path: '/operation/batterys',
    name: 'OperationBatterys',
    component: OperationBatterys,
  },
  {
    path: '/operation/OperationBattery',
    name: 'OperationBattery',
    component: OperationBattery,
    meta: { title: t('menu.distribution_battery') } 
  },
  {
    path: '/operation/scatter',
    name: 'OperationScatter',
    component: OperationScatter
  },
  {
    path: '/operation/users',
    name: 'OperationUsers',
    component: OperationUsers,
    meta: { title: t('menu.decision.user') } 
  },
  {
    path: '/operation/properties',
    name: 'OperationProperties',
    component: OperationProperties,
    meta: { title: t('menu.decision.properties') } 
  },
  {
    path: '/operation/orders',
    name: 'OperationOrders',
    component: OperationOrders,
    meta: { title: t('menu.decision.order') }
  },
  {
    path: '/operation/orders',
    name: 'OperationBikes',
    component: OperationBikes
  },
  {
    path: '/finance/report',
    name: 'FinanceReport',
    component: FinanceReport
  },
  {
    path: '/log/system',
    name: 'LogSystem',
    component: LogSystem,
    meta: { title: t('menu.log_sys') }
  },
  {
    path: '/log/api',
    name: 'LogApi',
    component: LogApi,
    meta: { title: t('menu.log_api'), keepAlive:true }
  },
  {
    path: '/log/msg',
    name: 'msgLog',
    component: msgLog,
    meta: { title: t('menu.msg_log'), keepAlive:true }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/bigScreen',
    name: 'bigScreen',
    component: () => import('../views/bigScreen/index.vue')
  },
  {
    path: '/card/list',
    name: 'CardList',
    component: CardList,
    meta: { title: t('menu.card_list'), keepAlive:true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
