<template>
  <div class="content">

    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>

        <div class="item">
          <span class="label">{{ $t('device.status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("member.mobile") }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhoneNum"
            :placeholder="$t('member.mobile')"></el-input>
        </div>
        <div class="item">
          <span class="label">美团订单号</span>
          <el-input class="inp" size="small" v-model="formData.valMeituOrder" placeholder="美团订单号"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.updateMeiTuan" class="btn-export" type="primary">更新数据</el-button>
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table @row-click.self="func.showDetails" :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="Id" width="60">
        </el-table-column>
        <el-table-column prop="OrderMobile" :label="$t('member.mobile')" width="140">
        </el-table-column>
        <el-table-column prop="AgentCode" :label="$t('g.agent_code')" v-if="!isOperator()" width="80">
        </el-table-column>
        <el-table-column prop="OrderId" label="美团订单号" width="180">
        </el-table-column>
        <el-table-column width="120" :label="$t('expenses.status')">
          <template #default="scope">
            <el-tag :class="scope.row.Status == 2 ? 'tag-success' : (scope.row.Status == 1 ? 'tag-info' : 'tag-fail')">
              {{ render.statuses[scope.row.Status].label || '' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="OrderStatus" label="美团状态" width="100">
          <template #default="scope">
            <el-tag v-if="scope.row.OrderStatus == 1">
              待付款
            </el-tag>
            <el-tag v-else-if="scope.row.OrderStatus == 10" class="tag-success">
              已付款
            </el-tag>
            <el-tag v-else-if="scope.row.OrderStatus == 20" class="tag-fail">
              订单取消
            </el-tag>
            <el-tag v-else-if="scope.row.OrderStatus == 40" class="tag-success">
              已发货
            </el-tag>
            <el-tag v-else-if="scope.row.OrderStatus == 90" class="tag-success">
              已完成
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="ActuallyPrice" :label="$t('order.combo.actualAmount')" width="100">
        </el-table-column>
        <el-table-column prop="Remark" :label="$t('order.remark')" width="100">
        </el-table-column>
        <el-table-column prop="NamePath" :label="$t('g.operator_ownership')" :show-overflow-tooltip='true'
          min-width="230">
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column width="260" :label="$t('g.operation')" fixed="right" v-if="isPath()">
          <template #default="scope">
            <el-button @click="func.onCancelOrder(scope.row)" type="info"
              :class="scope.row.OrderStatus == 10 ? 'btn-delete' : 'tag-info'"
              :disabled="scope.row.OrderStatus != 10">取消订单</el-button>
            <el-button @click="func.onRefund(scope.row)" :class="scope.row.OrderStatus == 40 ? 'btn-add' : 'tag-info'"
              type="info" :disabled="scope.row.OrderStatus != 40">退款</el-button>
            <el-button @click="func.writeOff(scope.row)"
              :class="scope.row.OrderStatus == 10 ? 'btn-operate' : 'tag-info'" type="info" style="margin-left: 10px;"
              :disabled="scope.row.OrderStatus != 10">销账</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
        }}</span>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="80%" align-center>
      <el-descriptions border>
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{ formData.curData.OrderMobile }}</el-descriptions-item>
        <el-descriptions-item label="用户Id">{{ formData.curData.UserId || '' }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
        }}</el-descriptions-item>
        <el-descriptions-item label="美团订单号">
          {{ formData.curData.OrderId }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('expenses.status')">
          <el-tag
            :class="formData.curData.Status == 2 ? 'tag-success' : (formData.curData.Status == 1 ? 'tag-info' : 'tag-fail')">
            {{ render.statuses[formData.curData.Status].label || '' }}
          </el-tag>
        </el-descriptions-item>

        <el-descriptions-item label="美团状态">
          <el-tag v-show="formData.curData.OrderStatus == 1">
            待付款
          </el-tag>
          <el-tag v-show="formData.curData.OrderStatus == 10" class="tag-success">
            已付款
          </el-tag>
          <el-tag v-show="formData.curData.OrderStatus == 20" class="tag-fail">
            订单取消
          </el-tag>
          <el-tag v-show="formData.curData.OrderStatus == 40" class="tag-success">
            已发货
          </el-tag>
          <el-tag v-show="formData.curData.OrderStatus == 90" class="tag-success">
            已完成
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('order.combo.actualAmount')">{{
          formData.curData.ActuallyPrice }}</el-descriptions-item>

        <el-descriptions-item label="平台承担费用">
          {{ formData.curData.PlatFormChargePrice }}
        </el-descriptions-item>
        <el-descriptions-item label="商家承担费用">
          {{ formData.curData.ProviderChargeRate }}
        </el-descriptions-item>
        <el-descriptions-item label="店铺优惠卷总优惠">
          {{ formData.curData.ProviderReduceFee }}
        </el-descriptions-item>
        <el-descriptions-item label="套餐Id">
          {{ formData.curData.ComboId }}
        </el-descriptions-item>
        <el-descriptions-item label="套餐名">
          {{ formData.curData.ComboName }}
        </el-descriptions-item>
        <el-descriptions-item label="套餐数量">
          {{ formData.curData.ComboNum }}{{ formData.curData.ComboType == 1 ? '月' : formData.curData.ComboType == 3 ?
            '天' : '次'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('order.remark')">{{
          formData.curData.Remark }}</el-descriptions-item>
        <el-descriptions-item label="更新用户Id">
          {{ formData.curData.UpdataUserId || '' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('g.operator_ownership')">{{ formData.curData.NamePath }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.update_at')">{{ formData.curData.UpdateAtStr
        }}</el-descriptions-item>

      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="销账" v-model="formData.dialogWriteoffVisible" width="80%" align-center>
      <el-descriptions border v-for="item in formData.userInfo" :key="item.Id" style="margin-bottom: 20px;">
        <el-descriptions-item label="用户Id">{{ item.Id }}</el-descriptions-item>
        <el-descriptions-item label="用户昵称">{{ item.Nickname }}</el-descriptions-item>
        <el-descriptions-item label="用户姓名">{{ item.IdName }}</el-descriptions-item>
        <el-descriptions-item label="用户手机">{{ item.Mobile }}</el-descriptions-item>
        <el-descriptions-item label="美团实付金额">{{ formData.curData.ActuallyPrice }}</el-descriptions-item>
        <el-descriptions-item label="套餐Id">{{ formData.curData.ComboId }}</el-descriptions-item>
        <el-descriptions-item label="套餐名">
          {{ formData.curData.ComboName }}
        </el-descriptions-item>
        <el-descriptions-item label="套餐数量">
          {{ formData.curData.ComboNum }}{{ formData.curData.ComboType == 1 ? '月' : formData.curData.ComboType == 3 ?
            '天' : '次'
          }}
        </el-descriptions-item>
        <el-descriptions-item label="是否购买过套餐"><el-tag :class="item.UserComboId ? 'tag-success' : 'tag-fail'">{{
          item.UserComboId ? '是' : '否' }}</el-tag></el-descriptions-item>
        <!-- <el-descriptions-item label="号码是否唯一"><el-tag class="tag-success">是</el-tag></el-descriptions-item> -->
        <el-descriptions-item label="创建时间">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
        <el-descriptions-item label="操作">
          <el-button class="btn-add" type="info" @click="func.addCombo(item)">新增套餐</el-button>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog title="退款" v-model="formData.dialogRefundVisible" align-center>
      <el-form label-width="100px" :model="formData.curData">
        <div class="el-form-row line-2">
          <el-form-item label="备注" style="width: 340px">
            <el-input v-model="formData.curData.Remark" placeholder="请输入备注">
            </el-input>
          </el-form-item>
          <el-form-item label="输入密码" style="width: 340px">
            <el-input v-model="formData.curData.password" type="password" placeholder="请输入密码">
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.refund()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="取消订单" v-model="formData.dialogcancelOrderVisible" align-center>
      <el-form label-width="100px" :model="formData.curData">
        <div class="el-form-row line-2">
          <el-form-item label="备注" style="width: 340px">
            <el-input v-model="formData.curData.Remark" placeholder="请输入备注">
            </el-input>
          </el-form-item>
          <el-form-item label="输入密码" style="width: 340px">
            <el-input v-model="formData.curData.password" type="password" placeholder="请输入密码">
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.cancelOrder()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, watch } from 'vue'
import { operators, updateOperators, shortcuts, operatorList, cascade_options_direct } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/data/i18n/index'
import { isOperator, isPath, isAdmin } from '@/data/token'
import { nextTick } from 'vue'
import { el, pa } from 'element-plus/lib/locales.js'

export default {
  name: 'ExpensesCombo',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: '所有',
        value: 0
      },
      {
        label: '未处理',
        value: 1
      },
      {
        label: '套餐已充值',
        value: 2
      },
      {
        label: '已取消',
        value: 3
      },
      {
        label: '已退款',
        value: 4
      }
    ]


    const render = {
      operators,
      statuses,
      updateOperatorsList: operatorList,
      shortcuts,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      curData: {},
      userInfo: [],
      valTime: '',
      valStatus: 0,
      valType: 0,
      dialogWriteoffVisible: false,
      dialogDisplayVisible: false,
      dialogRefundVisible: false,
      dialogcancelOrderVisible: false,
      curPage: 1,
      pageSize: 10,
      dataImg: {
        Img1: ''
      },
      total: 500,
      valOperator: 0,
      devType: [],
      eboxData: [],
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      IsStartWith: 1,
      is_opt: false
    })

    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },

      async showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.curData = row;
        formData.dialogDisplayVisible = true;
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      onCancelOrder(row) {
        formData.curData = row;
        formData.dialogcancelOrderVisible = true
      },
      onRefund(row) {
        formData.curData = row;
        formData.dialogRefundVisible = true
      },
      async cancelOrder(row) {
        // ElMessageBox.prompt('输入取消订单备注信息', '确认取消订单', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(async ({ value }) => {
        let params = {
          OrderId: formData.curData.OrderId,
          Remark: formData.curData.Remark
        }
        if (formData.curData.password) {
          params.Password = formData.curData.password
        } else {
          return ElMessage({
            type: 'error',
            message: '请输入密码'
          })
        }
        try {
          let res = await axios.post('/admin/ordermtzb/cancelorder',
            params
          )
          ElMessage({
            type: 'success',
            message: res
          })
          formData.dialogcancelOrderVisible = false
          func.loadData()
        } catch (e) {
          // ElMessage({
          //   type: 'error',
          //   message: e
          // })
        }
        // console.log('refund', row)
        // }).catch(() => {
        //   // console.log('cancel')
        // })
      },
      async getUserInfo() {
        try {
          let params = {
            agentcode: formData.curData.CodePath,
            phoneNum: formData.curData.OrderMobile,
            IsStartWith: 1
          }
          let res = await axios.get('/admin/user', { params })
          if (res && res.List.length > 0) {
            formData.userInfo = res.List
            formData.dialogWriteoffVisible = true;
          } else {
            return ElMessage({
              type: 'error',
              message: '未找到手机用户' + formData.curData.OrderMobile
            })
          }
        } catch (e) {
          console.log('getUserInfo', e)
        }
      },
      async refund(row) {
        // ElMessageBox.prompt('输入退款备注信息', '确认退款', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(async ({ value }) => {
        let params = {
          OrderId: formData.curData.OrderId,
          Remark: formData.curData.Remark
        }
        if (formData.curData.password) {
          params.Password = formData.curData.password
        } else {
          return ElMessage({
            type: 'error',
            message: '请输入密码'
          })
        }
        try {
          let res = await axios.post('/admin/ordermtzb/refundorder', params)
          ElMessage({
            type: 'success',
            message: res
          })
          formData.dialogRefundVisible = false
          func.loadData()
        } catch (e) {
          // ElMessage({
          //   type: 'error',
          //   message: e
          // })
        }
        // console.log('refund', row)
        // }).catch(() => {
        //   // console.log('cancel')
        // })

      },
      writeOff(row) {
        formData.curData = row;
        formData.userInfo = []
        func.getUserInfo()

      },
      async updateMeiTuan() {
        try {
          let res = await axios.post('/admin/ordermtzb/orderget')
          ElMessage({
            type: 'success',
            message: '跟新成功'
          })
          func.loadData()
        } catch (e) {
          ElMessage({
            type: 'error',
            message: e
          })
        }
      },
      async addCombo(row) {
        try {
          if (!formData.curData.OrderId || !row.Id) {
            return ElMessage({
              type: 'error',
              message: '参数错误'
            })
          }
          let res = await axios.post('/admin/ordermtzb/sendexpress', {
            UserId: row.Id,
            OrderId: formData.curData.OrderId,
          })
          formData.dialogWriteoffVisible = false;
          ElMessage({
            type: 'success',
            message: res
          })
          func.loadData()
        } catch (e) {
          // ElMessage({
          //   type: 'error',
          //   message: e
          // })
        }
      },
      async updateData() {
        try {
          let params = {
            Name: formData.curData.Name,
            MaxUserNum: formData.curData.MaxUserNum,
            Status: formData.curData.Status,
            Language: formData.curData.Language,
            EboxId: formData.curData.EboxId,
            EboxType: formData.curData.EboxType,
            Remark: formData.curData.Remark,
          }
          if (formData.curData.CodePath) {
            params.CodePath = formData.curData.CodePath
          }
          if (formData.curData.agentCode) {
            params.AgentCode = formData.curData.agentCode
          }
          if (formData.curData.ExpireTime) {
            params.ExpireTime = formData.curData.ExpireTime
          }
          // if (formData.curData.WebUrl) {
          //   params.WebUrl = formData.curData.WebUrl
          // }
          if (formData.curData.Id) {
            params.Id = formData.curData.Id
            await axios.post('/admin/webshow/edit', params)
          } else {
            if (formData.curData.agentCode && formData.curData.EboxId) {
              params.WebUrl = 'http://' + formData.curData.agentCode.toLowerCase() + '.wxrule.smart2charge.com/device?id=' + formData.curData.EboxId + '&h5Id=';
            }
            await axios.post('/admin/webshow/add', params)
          }
        } catch (e) {

        }
        func.loadData()
      },
      opt_operator() {
        formData.curData.DevTypeStr = ''
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valStatus != 0) {
            params.Status = formData.valStatus
          }
          if (formData.valOperator) {
            params.CodePath = formData.valOperator
          }
          if (formData.valPhoneNum) {
            params.OrderMobile = formData.valPhoneNum
          }
          if (formData.valMeituOrder) {
            params.OrderId = formData.valMeituOrder
          }
          if (formData.valTime) {
            params.StartAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.EndAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/ordermtzb/get`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.UpdateAtStr = item.UpdateAt != 0 ? toDay(item.UpdateAt) : ''
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
      if (localStorage.getItem('user_info')) {
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
        if (formData.user_info.RoleId == 14 || formData.user_info.RoleId == 25 || formData.user_info.RoleId == 18) {
          formData.is_opt = true
        }
      }
    })
    return {
      func,
      render,
      isOperator,
      isAdmin,
      formData,
      isPath
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;


        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 33%;
    }

    .inp {
      width: 100%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}
</style>
