<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.valOperator"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary">{{ $t('g.search') }}</el-button>
        <el-button @click="func.showAddDataDialog" type="primary" class="btn-add">{{ $t('g.added') }}</el-button>
      </div>
      <div class="line"></div>
      <el-table @row-click.self="func.showDetails" :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="Id" width="60">
        </el-table-column>
        <el-table-column prop="Name" :label="$t('device.site_name')" width="140">
        </el-table-column>
        <el-table-column prop="AgentCode" :label="$t('g.agent_code')" v-if="!isOperator()">
        </el-table-column>
        <el-table-column prop="name_path" :label="$t('g.operator_ownership')" :show-overflow-tooltip='true'>
        </el-table-column>
        <!-- <el-table-column prop="ProvinceAndCityText" label="省市区" width="180">
          </el-table-column> -->
        <el-table-column prop="Address" :label="$t('siteManager.detailed_address')">
        </el-table-column>
        <el-table-column prop="OperationTime" :label="$t('siteManager.opening_hours')">
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column width="170" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-button @click="func.edit(scope.row)" class="btn-edit" type="primary">{{
              $t('g.edit')
            }}</el-button>
            <el-button @click="func.delete(scope.row.Id)" class="btn-delete" type="primary"
              style="margin-left: 10px;">{{ $t('g.delete') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="$t('g.edit')" v-model="formData.dialogVisible" width="80%" style="padding: 20px 30px;"
      align-center>
      <el-tabs v-model="formData.activeName" type="card" class="tabs" style="height: 542px;">
        <el-tab-pane :label="$t('siteManager.site_information')" name="first">
          <el-form label-width="100px" :model="formData.curData" style="margin-top: 45px;">
            <div class="box_flex">
              <el-form-item :label="$t('device.site_name')" style="width: 50%;">
                <el-input v-model="formData.curData.Name"></el-input>
              </el-form-item>
              <el-form-item :label="$t('siteManager.opening_hours')" style="width: 50%;">
                <el-input v-model="formData.curData.OperationTime"
                  :placeholder="$t('siteManager.site_txt1')"></el-input>
              </el-form-item>
            </div>
            <div class="box_flex">
              <el-form-item :label="$t('rights.agent')" v-if="formData.is_opt" style="width: 50%;">
                <el-cascader style="width: 100%;" :clearable="true" v-model="formData.curData.AgentCode"
                  :props="formData.props2" :options="render.cascade_options_direct" />
              </el-form-item>
              <el-form-item :label="$t('g.operator')" style="width: 50%;">
                <el-cascader style="width: 100%;" :clearable="true" v-model="formData.curData.CodePath"
                  :props="formData.props" :options="render.cascade_options_direct" />
              </el-form-item>
            </div>
            <div class="box_flex">
              <el-form-item :label="$t('siteManager.site_longitude')" style="width: 50%;">
                <el-input v-model="formData.curData.Longitude"></el-input>
              </el-form-item>
              <el-form-item :label="$t('siteManager.site_latitude')" style="width: 50%;">
                <el-input v-model="formData.curData.Latitude"></el-input>
              </el-form-item>
            </div>
            <!-- <el-form-item label="省市区" >
                        <el-cascader
                        v-model="formData.curData.ProvinceAndCity"
                        :options="city"
                        :props="formData.addres_props"
                        style="width: 100%;"
                        @change="func.optCity"
                        />
                    </el-form-item> -->

            <div class="box_flex">
              <el-form-item :label="$t('siteManager.parking_type')" style="width: 50%;">
                <el-select class="inp" v-model="formData.curData.ParkType">
                  <el-option v-for="item in render.parkingFee" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('siteManager.parking_information')" style="width: 50%;">
                <el-input v-model="formData.curData.ParkInfo"
                  :placeholder="$t('siteManager.parking_information')"></el-input>
              </el-form-item>
            </div>
            <el-form-item :label="$t('siteManager.operational_status')">
              <el-radio-group v-model="formData.curData.Status">
                <el-radio :value="0" size="large">{{ $t('siteManager.stop_business') }}</el-radio>
                <el-radio :value="1" size="large">{{ $t('siteManager.at_camp') }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="box_flex">
              <el-form-item :label="$t('siteManager.detailed_address')" style="width: 50%;">
                <el-input v-model="formData.curData.Address"></el-input>
              </el-form-item>
              <el-form-item :label="$t('siteManager.charging_station')" style="width: 50%;">
                <el-select class="inp" v-model="formData.curData.ChargeType">
                  <el-option v-for="item in render.chargingStation" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- :on-remove="handleRemove"  -->
            <el-form-item :label="$t('siteManager.site_images')" v-show="formData.form_type == 'edit'">
              <el-upload class="avatar-uploader" action="/admin/device/ImgsUp" list-type="picture-card" method="post"
                :limit="3" :headers="{ Authorization: token }" :http-request="(params) => func.onUpload(params, 0)"
                :show-file-list="false">
                <img v-if="formData.dataImg.Img0" :src="formData.dataImg.Img0" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
              <el-upload class="avatar-uploader" action="/admin/device/ImgsUp" list-type="picture-card" method="post"
                :limit="3" :headers="{ Authorization: token }" :http-request="(params) => func.onUpload(params, 1)"
                :show-file-list="false" style="margin-left: 20px;">
                <img v-if="formData.dataImg.Img1" :src="formData.dataImg.Img1" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
              <el-upload class="avatar-uploader" action="/admin/device/ImgsUp" list-type="picture-card" method="post"
                :limit="3" :headers="{ Authorization: token }" :http-request="(params) => func.onUpload(params, 2)"
                :show-file-list="false" style="margin-left: 20px;">
                <img v-if="formData.dataImg.Img2" :src="formData.dataImg.Img2" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('siteManager.charging_price')" name="second">
          <el-form :model="formData.curData" label-width="100px">
            <div class="form-row">
              <el-form-item :label="$t('menu.billStrategy')">
                <el-select class="inp" v-model="formData.curData.BillBaseSetId" @change="func.opt_strategy">
                  <el-option v-for="(item, index) in formData.time_slot" :key="index" :label="item.Name"
                    :value="item.Id">
                  </el-option>
                </el-select>
              </el-form-item>
              <div style="display: flex;">
                <div class="title_label">{{ $t('billingPolicy.charging_range') }}</div>
              </div>
              <div class="time_money">
                <div class="label_box">
                  <div class="title_label"></div>
                  <div class="inp_right" style="width: 100px">{{ $t('billingPolicy.bucket_type') }}</div>
                  <div class="inp_right" style="width: 120px">{{ $t('billingPolicy.start_time') }}</div>
                  <div class="inp_right" style="width: 120px">{{ $t('billingPolicy.end_time') }}</div>
                  <div class="inp_right" style="width: 100px">{{ $t('billingPolicy.settings_txt2') }}</div>
                  <div class="inp_right" style="width: 100px">{{ $t('billingPolicy.settings_txt3') }}</div>
                  <div class="inp_right" style="width: 100px">{{ $t('g.total') }}</div>
                </div>
                <div class="money_box" v-for="(item, index) in formData.timeInfo" :key="index">
                  <div class="title_label"></div>
                  <el-input class="inp_right" :value="item.typeText" disabled style="width: 100px" />
                  <el-input class="inp_right" :value="item.strtime" disabled style="width: 120px" />
                  <el-input class="inp_right" :value="item.endtime" disabled style="width: 120px" />
                  <el-input class="inp_right" disabled :value="item.electricity_bill / 1000" style="width: 100px">
                  </el-input>
                  <el-input class="inp_right" disabled :value="item.service_charge / 1000" style="width: 100px">
                  </el-input>
                  <div class="inp_right" v-if="item.electricity_bill && item.service_charge" style="width: 100px;">{{
                    $t('g.sum') }}:{{
                      ((Number(item.electricity_bill) + Number(item.service_charge)) / 1000).toFixed(2) }}{{
                      $t('billingPolicy.settings_txt5') }}</div>
                  <div class="inp_right" v-else style="width: 100px;">共:0(元/度) </div>
                </div>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDetailVisible" width="80%" style="padding: 20px 30px;"
      align-center>
      <el-descriptions border>
        <el-descriptions-item :label="$t('device.site_name')">{{
          formData.curData.Name
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('siteManager.opening_hours')">{{
          formData.curData.OperationTime
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.operator')">{{
          formData.curData.CodePath
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('siteManager.site_longitude')">{{
          formData.curData.Longitude
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('siteManager.site_latitude')">{{
          formData.curData.Latitude
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('siteManager.parking_type')">{{
          render.parkingFee[formData.curData.ParkType].label
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('siteManager.parking_information')">{{
          formData.curData.ParkInfo
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('siteManager.detailed_address')">{{
          formData.curData.Address
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('menu.billStrategy')">{{
          formData.time_slot[formData.curData.BillBaseSetId]?.Name
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('siteManager.charging_station')">{{
          render.chargingStation[formData.curData.ChargeType]?.label
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{
          formData.curData.CreateAtStr
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.update_at')">{{
          formData.curData.UpdateAtStr
        }}</el-descriptions-item>
      </el-descriptions>
      <el-table :data="formData.timeInfo" style="width: 100%;margin-top: 10px;" border>
        <el-table-column prop="typeText" :label="$t('siteManager.time_slot')">
        </el-table-column>
        <el-table-column prop="strtime" :label="$t('billingPolicy.start_time')">
        </el-table-column>
        <el-table-column prop="endtime" :label="$t('billingPolicy.end_time')">
        </el-table-column>
        <el-table-column prop="electricity_bill" :label="$t('billingPolicy.electricity')">
          <template #default="scope">
            {{ (scope.row.electricity_bill / 1000).toFixed(2) }}{{ $t('billingPolicy.settings_txt5') }}
          </template>
        </el-table-column>
        <el-table-column prop="service_charge" :label="$t('billingPolicy.service_charge')">
          <template #default="scope">
            {{ (scope.row.service_charge / 1000).toFixed(2) }}{{ $t('billingPolicy.settings_txt5') }}
          </template>
        </el-table-column>
        <el-table-column prop="strtime" :label="$t('g.total')">
          <template #default="scope">
            {{ ((scope.row.electricity_bill + scope.row.service_charge) / 1000).toFixed(2) }}{{
              $t('billingPolicy.settings_txt5') }}
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed, watch } from 'vue'
import { operators, updateOperators, shortcuts, operatorList, cascade_options_direct } from '@/data/default'
import { toDay } from '@/common/func'
import city from '@/common/city.json'
import axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import i18n from '@/data/i18n/index'
import { isOperator, isPath, isAdmin, user } from '@/data/token'
import cfg from '@/common/conf'
export default {
  name: 'ExpensesCombo',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const render = {
      operators,
      parkingFee: [
        {
          label: t('siteManager.parking_fee'),
          value: 1
        },
        {
          label: t('siteManager.free_parking'),
          value: 2
        }, {
          label: t('siteManager.free_time'),
          value: 3
        },
        {
          label: t('siteManager.charging_free'),
          value: 4
        }
      ],
      chargingStation: [
        {
          label: t('siteManager.public_stations'),
          value: 1
        },
        {
          label: t('siteManager.commercial_stations'),
          value: 2
        }, {
          label: t('siteManager.residential_station'),
          value: 3
        },
        {
          label: t('siteManager.highway_station'),
          value: 4
        },
        {
          label: t('siteManager.smart_station'),
          value: 5
        }
      ],
      shortcuts,
      cascade_options_direct
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valStatus: 0,
      valType: 0,
      dialogVisible: false,
      dialogDetailVisible: false,
      dialogDisplayVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 500,
      valOperator: 0,
      devType: [],
      props: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true
      },
      props2: {
        children: "Children", expandTrigger: 'hover',
        label: "Name",
        value: "Code",
        emitPath: false,
        checkStrictly: true
      },
      addres_props: {
        children: "children", expandTrigger: 'hover',
        label: "name",
        value: "name",
        emitPath: true,
        checkStrictly: true
      },
      IsStartWith: 1,
      is_opt: false,
      activeName: 'first',
      time_slot: [],
      timeInfo: [],
      form_type: 'add',
      fileList: [],
      uploadVisible: false,
      dialogImageUrl: '',
      dataImg: {
        Img0: '',
        Img1: '',
        Img2: ''
      }
    })
    watch(() => formData.curData.CodePath, () => {
      //let simplerWay = formData.curData.agentPath.slice(0, -1).split('/').pop(); 
      func.getDevType(formData.curData.CodePath)
    })
    onMounted(() => {
      func.loadData()
      func.getDevType()
      // func.get_billbaseset()
      if (localStorage.getItem('user_info')) {
        formData.user_info = JSON.parse(localStorage.getItem('user_info'))
        if (formData.user_info.RoleId == 14 || formData.user_info.RoleId == 25 || formData.user_info.RoleId == 18) {
          formData.is_opt = true
        }
      }
    })
    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      async getDevType(CodePath) {
        let params = {}
        params.CodePath = CodePath
        params.getAll = 1
        let res = await axios.get('/admin/box/devtypes', { params })
        if (res) {
          formData.devType = res
        }
      },
      async showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.curData = row;
        let index = formData.time_slot.findIndex(item => item.Id === formData.curData.BillBaseSetId);
        if (formData.time_slot[index]) {
          formData.timeInfo = JSON.parse(formData.time_slot[index].BillTime)
          formData.timeInfo.map((item) => {
            if (item.type == 0) {
              item.typeText = '尖时段'
            } else if (item.type == 1) {
              item.typeText = '峰时段'
            } else if (item.type == 2) {
              item.typeText = '平时段'
            } else if (item.type == 3) {
              item.typeText = '谷时段'
            }
          })
        }
        formData.dialogDetailVisible = true;
      },
      handlePictureCardPreview(uploadFile) {
        formData.dialogImageUrl.value = uploadFile.url
        formData.uploadVisible.value = true
      },
      edit(row, column, e) {
        formData.curData = row
        delete formData.curData.DevId
        delete formData.curData.DevTypeStr
        formData.form_type = 'edit'
        func.opt_strategy()
        func.getImage()
        // formData.curData.CodePath = row.CodePath
        // formData.curData.AgentCode = row.AgentCode
        formData.dialogVisible = true
      },
      async getImage() {
        formData.dataImg = {
          Img0: '',
          Img1: '',
          Img2: ''
        }
        let res = await axios.post('/admin/pilesite/imgget', {
          SiteCode: formData.curData.SiteCode
        })
        if (res) {
          if (res.Img0) {
            formData.dataImg.Img0 = 'data:image/bmp;base64,' + res.Img0;
          }
          if (res.Img1) {
            formData.dataImg.Img1 = 'data:image/bmp;base64,' + res.Img1;
          }
          if (res.Img2) {
            formData.dataImg.Img2 = 'data:image/bmp;base64,' + res.Img2;
          }
        }
      },
      async onUpload(data, type) {
        let fd = new FormData();
        let headers = {
          'Content-Type': 'multipart/form-data'
        }
        fd.append('Img', data.file)
        fd.append('ImgName', type)
        fd.append('SiteCode', formData.curData.SiteCode)
        let res = await axios.post('/admin/pilesite/imgup', fd, { headers })
        func.getImage()
      },
      async get_billbaseset() {
        let params = {
          getAll: 1
        }
        let res = await axios.get(`/admin/billbaseset/get`, {
          params
        })
        formData.time_slot = res.List
        console.log(formData.time_slot, '获取的值1')
      },
      async optCity() {
        //console.log(JSON.stringify(formData.curData.ProvinceAndCity) )
      },
      async delete(Id) {
        ElMessageBox.confirm(t('g.sure_delete'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(() => {
          try {
            axios.post('/admin/pilesite/delete', {
              Id
            }).then((res) => {
              if (res == '删除成功') {
                ElMessage({
                  type: 'success',
                  message: t('g.success')
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: t("err_code.20005")
                })
              }
              func.loadData()
            })

          } catch (error) {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }

        })
      },
      async opt_strategy(data) {
        let params = {
          getAll: 1
        }
        let res = await axios.get(`/admin/billbaseset/get`, {
          params
        })
        formData.time_slot = res.List
        let index = formData.time_slot.findIndex(item => item.Id === formData.curData.BillBaseSetId);
        if (formData.time_slot[index]) {
          formData.timeInfo = JSON.parse(formData.time_slot[index].BillTime)
          formData.timeInfo.map((item) => {
            if (item.type == 0) {
              item.typeText = '尖时段'
            } else if (item.type == 1) {
              item.typeText = '峰时段'
            } else if (item.type == 2) {
              item.typeText = '平时段'
            } else if (item.type == 3) {
              item.typeText = '谷时段'
            }
          })
        }
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
          Name: '',
          Longitude: '',
          Latitude: '',
          ParkType: 2,
          ChargeType: 2,
          Status: 1,
          BillBaseSetId: '',
          Address: '',
          // ProvinceAndCity:'',
          ParkInfo: '',
          OperationTime: ''
        }
        formData.form_type = 'add'
        formData.dialogVisible = true
      },
      async updateData() {
        // console.log("updateOrCreate Data",formData.curData) 
        if (!formData.curData.BillBaseSetId) {
          ElMessage({
            type: 'error',
            message: '请选择充电价格策略'
          })
          return
        }
        delete formData.curData.DevInfo
        let data = Object.values(formData.curData).some(value => value === null || value === undefined || value === '');
        if (data) {
          ElMessage({
            type: 'error',
            message: '请填写完整'
          })
          return
        }

        try {
          let params = Object.assign({}, formData.curData)
          // params.ProvinceAndCity = JSON.stringify(formData.curData.ProvinceAndCity)
          if (formData.form_type == 'edit') {
            params.Id = formData.curData.Id
            await axios.post('/admin/pilesite/edit', params)
          } else {
            await axios.post('/admin/pilesite/add', params)
          }
        } catch (e) {

        }
        formData.dialogVisible = false
        func.loadData()
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page

          let params = {
            page,
          }
          if (formData.valOperator) {
            params.CodePath = formData.valOperator
          }
          let res = await axios.get(`/admin/pilesite/get`, {
            params
          })

          console.log('列表信息', res)
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.UpdateAtStr = toDay(item.UpdateAt)
            // if(item.ProvinceAndCity){
            //   let adress = JSON.parse(item.ProvinceAndCity) 
            //   item.ProvinceAndCity = adress
            //   item.ProvinceAndCityText = adress[0]+adress[1]+adress[2]
            // }
          })
          formData.tableData = res.List
          formData.PageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }

    return {
      func,
      render,
      isOperator,
      isAdmin,
      formData,
      isPath,
      city,
      uploadUrl: cfg.uploadUrl,
      token: user.token,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: grid;
      margin: 0 auto;
      margin-top: 1rem;
      grid-template-columns: repeat(4, 24.1%);
      gap: 14px 16px;

      >.item {
        display: flex;
        align-items: center;


        .label {
          display: flex;
          justify-content: left;
          min-width: 76px;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 33%;
    }

    .inp {
      width: 100%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

.box_flex {
  display: flex;
}

.tabs {
  margin-bottom: 30px;
}

:deep .el-tabs--card>.el-tabs__header {
  border-top: 0px solid #e4e7ed !important;
}

:deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed !important;
}

:deep .el-tabs--card>.el-tabs__header {
  background-color: #fff !important;
}


.form-row {
  text-align: left;
  // padding: 0 60px;
  margin-top: 45px;

  .inp {
    width: 500px;
  }

  .inp_right {
    margin-right: 15px;
  }

  .label_box {
    display: flex;
    margin-bottom: 15px
  }

  .title_label {
    width: 100px;
    text-align: right;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  .money_box {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .time_money {
    margin-top: 30px
  }
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

:deep .avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

:deep .avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

:deep .el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>